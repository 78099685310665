import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { fetchDataOld } from "../../../../services/fetchData";
import Reload from '../../../utils/reload';
import { Card, Table, Badge } from 'react-bootstrap';

// Enregistrer les composants nécessaires pour Chart.js
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const PerformanceAgenceChart = () => {
  const [performanceData, setPerformanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [viewMode, setViewMode] = useState('chart'); // 'chart' ou 'table'

  useEffect(() => {
    setLoading(true);
    fetchDataOld("agences_performance", setPerformanceData, setError)
      .then(() => setLoading(false))
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  if (loading) return <Reload />;
  if (error) return <p className="text-white">Erreur lors du chargement des données: {error.message}</p>;
  if (!performanceData || performanceData.length === 0) return <p className="text-white">Aucune donnée disponible</p>;

  // Trier les agences par performance (revenus)
  const sortedAgences = [...performanceData].sort((a, b) => b.montant_total - a.montant_total);

  // Configuration du graphique pour un affichage horizontal (plus adapté aux écrans larges)
  const chartData = {
    labels: sortedAgences.map(agence => agence.nom),
    datasets: [
      {
        label: 'Revenus (FCFA)',
        data: sortedAgences.map(agence => agence.montant_total),
        backgroundColor: sortedAgences.map(agence => agence.backgroundColor || 'rgba(75, 192, 192, 0.2)'),
        borderColor: sortedAgences.map(agence => agence.borderColor || 'rgba(75, 192, 192, 1)'),
        borderWidth: 1,
      }
    ],
  };

  // Options optimisées pour le remplissage maximum de l'espace disponible
  const options = {
    indexAxis: 'y', // Utilisation d'un graphique à barres horizontales pour mieux utiliser l'espace
    maintainAspectRatio: false, // Permet au graphique de s'adapter à la hauteur du conteneur
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#FFFFFF',
          boxWidth: 15, // Réduire la taille des éléments de légende
          padding: 10,
        }
      },
      title: {
        display: true,
        text: 'Classement des agences par performance',
        color: '#FFFFFF',
        font: {
          size: 16,
        },
        padding: {
          bottom: 10, // Réduire l'espace sous le titre
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.x !== null) {
              label += new Intl.NumberFormat('fr-FR', { 
                style: 'currency', 
                currency: 'XAF',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(context.parsed.x);
            }
            return label;
          }
        }
      }
    },
    scales: {
      y: {
        ticks: {
          color: '#FFFFFF',
          padding: 5, // Réduire l'espace entre les étiquettes
          font: {
            size: 11, // Réduire la taille de la police
          }
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        }
      },
      x: {
        beginAtZero: true,
        ticks: {
          color: '#FFFFFF',
          padding: 5, // Réduire l'espace entre les étiquettes
          callback: function(value) {
            // Formater les valeurs avec des suffixes pour économiser de l'espace
            if (value >= 1000000) return (value / 1000000).toFixed(1) + 'M FCFA';
            if (value >= 1000) return (value / 1000).toFixed(0) + 'K FCFA';
            return value + ' FCFA';
          },
          font: {
            size: 10, // Réduire la taille de la police
          }
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        }
      }
    },
    layout: {
      padding: {
        top: 5,
        right: 15,
        bottom: 5,
        left: 15
      }
    }
  };

  // Formatage des montants pour le tableau
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('fr-FR', { 
      style: 'currency', 
      currency: 'XAF',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  return (
    <div className="performance-chart-container">
      {/* Boutons de basculement entre les modes d'affichage */}
      <div className="d-flex justify-content-end mb-2">
        <div className="btn-group btn-group-sm">
          <button 
            className={`btn ${viewMode === 'chart' ? 'btn-primary' : 'btn-outline-secondary'}`}
            onClick={() => setViewMode('chart')}
          >
            Graphique
          </button>
          <button 
            className={`btn ${viewMode === 'table' ? 'btn-primary' : 'btn-outline-secondary'}`}
            onClick={() => setViewMode('table')}
          >
            Tableau
          </button>
        </div>
      </div>

      {viewMode === 'chart' ? (
        // Vue graphique - optimisée pour remplir l'espace disponible
        <div style={{ height: 'calc(100vh - 250px)', maxHeight: '500px', width: '100%' }}>
          <Bar data={chartData} options={options} />
        </div>
      ) : (
        // Vue tableau - plus compacte et informative
        <Card bg="dark" text="white" className="table-responsive" style={{ maxHeight: 'calc(100vh - 250px)' }}>
          <Table responsive hover variant="dark" className="mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Agence</th>
                <th>Ville</th>
                <th className="text-end">Réservations</th>
                <th className="text-end">Colis</th>
                <th className="text-end">Revenus totaux</th>
              </tr>
            </thead>
            <tbody>
              {sortedAgences.map((agence, index) => (
                <tr key={agence.idAgence} className={index < 3 ? 'table-success bg-opacity-25' : ''}>
                  <td>{index + 1}</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <span 
                        className="d-inline-block me-2" 
                        style={{ 
                          width: '10px', 
                          height: '10px', 
                          backgroundColor: agence.backgroundColor || 'rgba(75, 192, 192, 0.2)',
                          borderRadius: '50%'
                        }}
                      ></span>
                      {agence.nom}
                    </div>
                  </td>
                  <td>{agence.ville}</td>
                  <td className="text-end">
                    <Badge bg="info">{agence.total_reservations}</Badge>
                  </td>
                  <td className="text-end">
                    <Badge bg="warning" text="dark">{agence.total_colis}</Badge>
                  </td>
                  <td className="text-end fw-bold">{formatCurrency(agence.montant_total)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      )}
    </div>
  );
};

export default PerformanceAgenceChart;
