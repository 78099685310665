import React, { useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import {
  getElement,
  addBusDepart,
  generatePDF,
  fetchDataOld,
} from "../../../../services/fetchData";
import { formatEdit } from "../../../utils/numberFormat";
import "../../../../pages/global/style_global.scss";
import NotificationManager from "../../../utils/notificationManger";
import MenuIcon from "@mui/icons-material/Menu";
import Reload from "../../../utils/reload";
import {
  useDeleteMutation,
  usePostMutation,
} from "../../../utils/useCustomQuery ";
import ConfirmYesNo from "../../../modal/confirmYesNo";
import moment from "moment";
// import generateWord from "../../../modelFusion/FusionWord";

const UpdateBusDepart = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [busData, setBusData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [notificationQueue, setNotificationQueue] = useState([]);

  // Modal de confirmation de suppression
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentToDelete, setCurrentToDelete] = useState(null);

  // eslint-disable-next-line
  const [isRequired, setIsRequired] = useState([]);
  // eslint-disable-next-line
  const saveButtonRef = useRef();
  const [setError] = useState(null);
  const [allBus, setAllBus] = useState([]);
  const [busPDF, setBusPDF] = useState([]);
  const [reservationPDF, setReservationPDF] = useState([]);
  const [employePDF, setEmployePDF] = useState([]);
  const [filteredBagagistes, setFilteredBagagistes] = useState([]);
  const [agenceResponsables, setAgenceResponsables] = useState({});

  const nidBusDepartLink = id;
  const initialFormData = useMemo(
    () => ({
      depart: "",
      arrive: "",
      dateDepart: "",
      heureDepart: "",
      heureArrive: "",
      fraisCarburant: 0,
      fraisRation: 0,
      idAgence: 0,
      idBus: 0,
      zoneDepart: "",
      zoneArrive: "",
      idEmploye: "",
      nom_employe: "",
      prenom_employe: "",
      description: "",
      idSousAgence: 0,
      idBusdepart: nidBusDepartLink,
    }),
    [nidBusDepartLink]
  );
  const [sousAgences, setSousAgences] = useState([]);
  const [filteredSousAgences, setFilteredSousAgences] = useState([]);


  const [formData, setFormData] = useState(initialFormData);
  useEffect(() => {

    getElement("view_bus_depart", "idBusDepart", id, (data) => {
      setBusData(data);
      // Mettez à jour initialFormData avec les données de l'employé si elles existent$
      if (data) {
        setFormData({
          ...initialFormData,
          depart: data.depart || initialFormData.depart,
          arrive: data.arrive || initialFormData.arrive,
          dateDepart: data.dateDepart || initialFormData.dateDepart,
          heureDepart: data.heureDepart || initialFormData.heureDepart,
          heureArrive: data.heureArrive || initialFormData.heureArrive,
          fraisCarburant: data.fraisCarburant || initialFormData.fraisCarburant,
          fraisRation: data.fraisRation || initialFormData.fraisRation,
          idBus: data.idBus || initialFormData.idBus,
          idAgence: data.idAgence || initialFormData.idAgence,
          zoneDepart: data.zoneDepart || initialFormData.zoneDepart,
          zoneArrive: data.zoneArrive || initialFormData.zoneArrive,
          idEmploye: data.idEmploye || initialFormData.idEmploye,
          nom_employe: data.nom_employe || initialFormData.nom_employe,
          prenom_employe: data.prenom_employe || initialFormData.prenom_employe,
          description: data.description || initialFormData.description,
          numeroTelephoneEmploye:
            data.numeroTelephone || initialFormData.numeroTelephone,
          idBusdepart: nidBusDepartLink,
          idSousAgence: data.idSousAgence || initialFormData.idSousAgence,
        });

        getElement("view_all_bus", "idBus", data.idBus, (data) => {
          setBusPDF((prevData) => ({
            ...prevData,
            modele: data.modele,
            immatriculation: data.immatriculation,
            capacite: data.capacite,
            idEmploye: data.idEmploye,
            nom_employe: data.nom_employe,
            prenom_employe: data.prenom_employe,
            numeroTelephone: data.numeroTelephone,
            nombreDePlaces: data.nombreDePlaces,
          }));
        });
      }
    });
    try {
      fetchDataOld("all_bus", setAllBus, setError);
      fetchDataOld("all_reservations", setReservationPDF, setError);
      fetchDataOld("all_employes", setEmployePDF, setError);
      fetchDataOld("all_sous_agences", setSousAgences, setError);
      // Récupérer les responsables des agences
      fetchDataOld("agence_responsables", (data) => {
        // Transformer la liste en objet pour un accès facile par idAgence
        const responsablesObj = {};
        if (Array.isArray(data)) {
          data.forEach(resp => {
            responsablesObj[resp.idAgence] = {
              nom: resp.nom,
              prenom: resp.prenom,
              fonction: resp.fonction
            };
          });
        }
        setAgenceResponsables(responsablesObj);
      }, setError);
    } catch (error) {
      showErrorNotification();
    }
    if (id === "new") {
      setIsEditMode(true);
    }
    // eslint-disable-next-line
  }, [id, nidBusDepartLink, initialFormData]);

  // Effet pour filtrer les sous-agences en fonction de l'agence sélectionnée
  useEffect(() => {
    if (formData.idAgence) {
      const agenceId = parseInt(formData.idAgence);
      const filtered = sousAgences.filter(sa => sa.idAgence === agenceId);
      setFilteredSousAgences(filtered);
    } else {
      setFilteredSousAgences([]);
    }
  }, [formData.idAgence, sousAgences]);

  // Nouvel effet pour filtrer les bagagistes selon l'agence sélectionnée
  useEffect(() => {
    // Définir les correspondances entre idAgence et nom d'agence
    const agenceMapping = {
      '1': 'Agence Brazzaville',
      '2': 'Agence Pointe-Noire', 
      '3': 'Agence Dolisie'
    };
    
    if (formData.idAgence && employePDF && employePDF.length > 0) {
      const agenceNom = agenceMapping[formData.idAgence];
      
      // Filtrer les bagagistes qui appartiennent à l'agence sélectionnée
      const bagagistesFiltres = employePDF.filter(
        employe => employe.fonction === "Bagagiste" && employe.agence === agenceNom
      );
      
      setFilteredBagagistes(bagagistesFiltres);
      
      // Si le bagagiste actuel n'appartient pas à cette agence, réinitialiser la sélection
      if (formData.idEmploye) {
        const bagagisteActuel = employePDF.find(
          e => e.idEmploye == formData.idEmploye
        );
        if (bagagisteActuel && bagagisteActuel.agence !== agenceNom) {
          setFormData(prevData => ({
            ...prevData,
            idEmploye: '',
          }));
        }
      }
    } else {
      // Si aucune agence n'est sélectionnée, afficher tous les bagagistes
      const allBagagistes = employePDF ? employePDF.filter(employe => employe.fonction === "Bagagiste") : [];
      setFilteredBagagistes(allBagagistes);
    }
  }, [formData.idAgence, employePDF]);

  const reservationBusDepart = reservationPDF.filter(
    (reservation) => reservation.idBusdepart === parseInt(nidBusDepartLink)
  );


  const updateField = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setBusData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };
  const handleSaveClick = () => {
    if (!isRequired) {
      setIsEditMode(false);
      showSuccessNotification();
    }
  };
  const handleFieldChange = (event, fieldName) => {
    updateField(fieldName, event.target.value);
  };

  const addMutation = usePostMutation(["depart_bus"], addBusDepart);
  const deleteMutation = useDeleteMutation(["depart_bus"]);

  const handleOpenDeleteModal = (busData) => {
    setCurrentToDelete(busData);
    setShowConfirmModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    addMutation.mutate(formData, {
      onSuccess: () => {
        showSuccessNotification();
        setTimeout(() => {
          navigate("/depart_bus");
        }, 700);
      },
      onError: () => {
        showErrorNotification();
      },
    });
  };

  const handleBtnDelete = () => {
    if (currentToDelete) {
      deleteMutation.mutate(
        {
          tableName: "busDepart",
          idFieldName: "idBusDepart",
          id: nidBusDepartLink,
          cle: currentToDelete.cle,
        },
        {
          onSuccess: () => {
            setShowConfirmModal(false);
            showSuccessNotification();
            setTimeout(() => {
              navigate("/depart_bus");
            }, 700);
          },
          onError: () => {
            showErrorNotification();
          },
        }
      );
    }
  };

  const showSuccessNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: true, isError: false, isAlert: false },
    ]);
  };

  const showErrorNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: false, isError: true, isAlert: false },
    ]);
  };

  const zoneDepartBrazzaville = ["Kintele", "Plateaux de 15 ans", "Bifouti", "La poudrière"];
  const zoneDepartDolisie = [
    "Gare - Routière",
    "Tsila",
    "Barrière",
    "Bacongo",
    "Mboukou",
  ];
  const zoneDepartPointeNoire = ["Mpaka","Mont kamba", "VonVon", "Font Tié Tié"];

  const zone = (depart, arrive) => {
    if (depart === "Brazzaville" || arrive === "Brazzaville") {
      return zoneDepartBrazzaville;
    } else if (depart === "Dolisie" || arrive === "Dolisie") {
      return zoneDepartDolisie;
    } else if (depart === "Pointe-Noire" || arrive === "Pointe-Noire") {
      return zoneDepartPointeNoire;
    }
    // Vous pourriez vouloir retourner une valeur par défaut si aucune condition n'est remplie
    return undefined; // ou toute autre valeur par défaut selon votre logique
  };
  

  const simulateSaveButtonClick = () => {
    saveButtonRef.current.click();
  };

  // Fonction pour obtenir le responsable de l'agence actuelle
  const getAgenceResponsable = (idAgence) => {
    if (agenceResponsables && agenceResponsables[idAgence]) {
      return agenceResponsables[idAgence];
    }
    return { nom: "", prenom: "", fonction: "" };
  };

  if (!busData && id !== "new") {
    return <Reload />;
  }
  return (
    <div>
      <p className="fw-semibold m-3 mt-5 pt-3">
        <span className="text-secondary">Liste des départs des bus</span>{" "}
        <span className="text-secondary">&#62;</span>{" "}
        <NavLink
          to="/depart_bus"
          className="text-secondary text-decoration-none hover-link-in"
        >
          Tous les départs
        </NavLink>
        <span className="text-light"> &#62;</span>{" "}
        <span className="text-light">
          {busData
            ? `${busData.depart} - ${busData.arrive}`
            : "Nouveau départ de bus"}
        </span>
      </p>
      <div className="container-fluid my-2">
        <Form className="pt-3" onSubmit={handleSubmit}>
          <Card body>
            <div className="d-flex">
              <Card.Title className="pb-3 title">Détails du depart</Card.Title>
              {isEditMode ? (
                <Alert variant="warning" className="p-1 ms-auto fw-semibold">
                  Mode modification
                </Alert>
              ) : (
                <p></p>
              )}
              <Dropdown className="ms-auto">
                <Dropdown.Toggle id="dropdown-basic">
                  Outils <MenuIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {isEditMode ? (
                    <Dropdown.Item
                      style={{ fontSize: "0.9rem" }}
                      type="submit"
                      onClick={() => {
                        simulateSaveButtonClick();
                        handleSaveClick();
                      }}
                    >
                      Enregistré
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      style={{ fontSize: "0.9rem" }}
                      onClick={handleEditClick}
                    >
                      Modifier
                    </Dropdown.Item>
                  )}
                  {/* <Dropdown.Item
                    style={{ fontSize: "0.9rem" }}
                    onClick={() => handleOpenDeleteModal(busData)}
                  >
                    Itineraires
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    style={{ fontSize: "0.9rem" }}
                    onClick={async () => {
                      try {
                        // Récupérer le responsable de l'agence pour le manifeste
                        const responsable = getAgenceResponsable(formData.idAgence);
                        
                        await generatePDF(
                          "generate-ManifesteVoyageurPDF",
                          "Manifeste_voyageurs_" +
                            moment(formData.dateDepart, "YYYY-MM-DD").format(
                              "DD/MM/YYYY"
                            ),
                          [reservationBusDepart, formData, busPDF, responsable]
                        );

                        // generateWord([
                        //   reservationBusDepart,
                        //   formData,
                        //   busPDF,
                        //   responsable, // Ajouter le responsable aux données pour le document Word
                        // ]);

                      } catch (error) {
                        showErrorNotification();
                      }
                    }}
                  >
                    Manifeste voyageurs
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontSize: "0.9rem" }}
                    onClick={async () => {
                      try {
                        // Récupérer le responsable de l'agence pour le manifeste
                        const responsable = getAgenceResponsable(formData.idAgence);
                        
                        await generatePDF(
                          "generate-ManifesteColisPDF",
                          "Manifeste_colis_" +
                            moment(formData.dateDepart, "YYYY-MM-DD").format(
                              "DD/MM/YYYY"
                            ),
                          [
                            reservationBusDepart,
                            formData,
                            busPDF,
                            filteredBagagistes,
                            responsable // Ajouter le responsable aux données
                          ]
                        );
                      } catch (error) {
                        showErrorNotification();
                      }
                    }}
                  >
                    Manifeste colis
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontSize: "0.9rem" }}
                    onClick={() => handleOpenDeleteModal(busData)}
                  >
                    Supprimer
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <p className="" style={{ fontSize: "0.8rem !important" }}>
              Le formulaire d'informations des départs des bus.
            </p>
            <hr />

            <Card.Title className="pb-1 title">
              Horaire et destination
            </Card.Title>
            <p className="fw-semibold">
              Veuillez fournir ces informations pour le départ du bus.
            </p>
            <Form.Group as={Row} className="mb-2" controlId="Depart">
              <Form.Label column sm="2" lg="2" md="2" className="mb-4">
                Destination du bus
              </Form.Label>
              <Col sm="5">
                <Form.Select
                  aria-label="Départ"
                  value={busData ? busData.depart : formData.depart}
                  onChange={(event) => handleFieldChange(event, "depart")}
                  disabled={!isEditMode}
                  required
                >
                  <option value="" disabled>
                    Choisir départ
                  </option>
                  <option value="Brazzaville">Brazzaville</option>
                  <option value="Pointe-Noire">Pointe-Noire</option>
                  <option value="Dolisie">Dolisie</option>
                </Form.Select>
              </Col>
              <Col sm="5">
                <Form.Select
                  aria-label="Arrive"
                  value={busData ? busData.arrive : formData.arrive}
                  onChange={(event) => handleFieldChange(event, "arrive")}
                  disabled={!isEditMode}
                  required
                >
                  <option value="" disabled>
                    Choisir arrivée
                  </option>
                  <option value="Brazzaville">Brazzaville</option>
                  <option value="Pointe-Noire">Pointe-Noire</option>
                  <option value="Dolisie">Dolisie</option>
                </Form.Select>
              </Col>

              <Form.Label column sm="2" className="" lg="2" md="2">
                Zone de départ & arrivée
              </Form.Label>
              <Col sm="5" className="">
                <Form.Select
                  aria-label="Départ"
                  value={busData ? busData.zoneDepart : formData.zoneDepart}
                  onChange={(event) => handleFieldChange(event, "zoneDepart")}
                  disabled={!isEditMode}
                  required  
                >
                  <option value="" selected>
                    Choisir zone de départ
                  </option>
                  {(busData &&
                  busData.depart &&
                  Array.isArray(zone(busData.depart))
                    ? zone(busData.depart)
                    : formData &&
                      formData.depart &&
                      Array.isArray(zone(formData.depart))
                    ? zone(formData.depart)
                    : ["Inconnu", "No"]
                  ).map((zone) => (
                    <option key={zone} value={zone}>
                      {zone}
                    </option>
                  ))}
                </Form.Select>
              </Col>

              <Col sm="5" className=" mb-3">
                <Form.Select
                  aria-label="Départ"
                  value={busData ? busData.zoneArrive : formData.zoneArrive}
                  onChange={(event) => handleFieldChange(event, "zoneArrive")}
                  disabled={!isEditMode}
                  required
                >
                  <option value="" selected>
                    Choisir zone d'arrivée
                  </option>
                  {(busData &&
                  busData.arrive &&
                  Array.isArray(zone(busData.arrive))
                    ? zone(busData.arrive)
                    : formData &&
                      formData.arrive &&
                      Array.isArray(zone(formData.arrive))
                    ? zone(formData.arrive)
                    : ["Inconnu", "No"]
                  ).map((zone) => (
                    <option key={zone} value={zone}>
                      {zone}
                    </option>
                  ))}
                </Form.Select>
              </Col>

              <Form.Label column sm="2" lg="2" md="2" className="mb-3">
                Date & heure
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="date"
                  placeholder="Date de départ"
                  value={busData ? busData.dateDepart : formData.dateDepart}
                  onChange={(event) => handleFieldChange(event, "dateDepart")}
                  disabled={!isEditMode}
                  required={isRequired}
                />
              </Col>
              <Col sm="5">
                <Row>
                  <Col sm="5">
                    <Form.Label column>Heure de départ</Form.Label>
                  </Col>
                  <Col sm="7">
                    <Form.Control
                      type="time"
                      placeholder="Heure de depart"
                      value={
                        busData ? busData.heureDepart : formData.heureDepart
                      }
                      onChange={(event) =>
                        handleFieldChange(event, "heureDepart")
                      }
                      disabled={!isEditMode}
                      required={isRequired}
                    />
                  </Col>
                </Row>
              </Col>
              <Form.Label column sm="2" lg="2" md="2"></Form.Label>
              <Col sm="5"></Col>
              <Col sm="5">
                <Row>
                  <Col sm="5">
                    <Form.Label column>Heure de d'arrivée</Form.Label>
                  </Col>
                  <Col sm="7">
                    <Form.Control
                      type="time"
                      placeholder="Heure d'arrivée"
                      value={
                        busData ? busData.heureArrive : formData.heureArrive
                      }
                      onChange={(event) =>
                        handleFieldChange(event, "heureArrive")
                      }
                      disabled={!isEditMode}
                      required={isRequired}
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-4 mt-4" controlId="adress">
              <Form.Label column sm="2" lg="2" md="2">
                Infos bus
              </Form.Label>
              <Col sm="5">
                <Form.Select
                  aria-label="Bus"
                  value={busData ? busData.idBus : formData.idBus}
                  onChange={(event) => handleFieldChange(event, "idBus")}
                  disabled={!isEditMode}
                  required={isRequired}
                >
                  <option value="0" disabled>
                    Choisir le bus
                  </option>
                  {allBus.map((bus) => (
                    <option
                      key={bus ? bus.idBus : 0}
                      value={bus ? bus.idBus : 1}
                    >
                      🚍 {bus ? bus.immatriculation + " | " + bus.modele : ""}
                    </option>
                  ))}
                </Form.Select>
              </Col>

              <Col sm="5">
                <Row>
                  <Col sm="5">
                    <Form.Label column>Frais carburant</Form.Label>
                  </Col>
                  <Col sm="7">
                    <Form.Control
                      type="text"
                      placeholder="Frais carburant"
                      value={
                        busData
                          ? formatEdit(busData.fraisCarburant)
                          : formatEdit(formData.fraisCarburant)
                      }
                      onChange={(event) =>
                        handleFieldChange(event, "fraisCarburant")
                      }
                      disabled={!isEditMode}
                      required={isRequired}
                    />
                  </Col>
                </Row>
              </Col>
              {/* Ligne d'espce */}
              <Form.Label className="mt-3" column="2">
                Description
              </Form.Label>
              <Col sm="5" className="mt-3">
                <Form.Control
                  as="textarea"
                  aria-label="With textarea"
                  rows={5}
                  placeholder="Description"
                  value={busData ? busData.description : formData.description}
                  onChange={(event) => handleFieldChange(event, "description")}
                  disabled={!isEditMode}
                />
              </Col>

              <Col sm="5" className="mt-2">
                <Row>
                  <Col sm="5">
                    <Form.Label column>Frais ration chauffeur</Form.Label>
                  </Col>
                  <Col sm="7" className="mt-2">
                    <Form.Control
                      type="text"
                      placeholder="Frais ration"
                      value={
                        busData
                          ? formatEdit(busData.fraisRation)
                          : formatEdit(formData.fraisRation)
                      }
                      onChange={(event) =>
                        handleFieldChange(event, "fraisRation")
                      }
                      disabled={!isEditMode}
                      required={isRequired}
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>

            <hr />

            <Card.Title className="pb-1 title">
              Informations sur agence
            </Card.Title>
            <p className="fw-semibold">
              Cette section contient les détails essentiels relatifs à l'agence.
            </p>
            <hr />
            <Form.Group as={Row} className="mb-3" controlId="">
              <Col sm="5">
                <Form.Select
                  aria-label="Agence"
                  value={busData ? busData.idAgence : formData.idAgence}
                  onChange={(event) => handleFieldChange(event, "idAgence")}
                  disabled={!isEditMode}
                >
                  <option value="0" disabled>
                    Choisir l'agence
                  </option>
                  <option value="1">Agence Brazzaville</option>
                  <option value="2">Agence Pointe-Noire</option>
                  <option value="3">Agence Dolisie</option>
                </Form.Select>
              </Col>

              <Form.Label column sm="2" lg="2" md="2">
                Bagagiste
              </Form.Label>
              <Col sm="5">
                <Form.Select
                  aria-label="Bagagiste"
                  value={busData ? busData.idEmploye : formData.idEmploye}
                  onChange={(event) => handleFieldChange(event, "idEmploye")}
                  disabled={!isEditMode || filteredBagagistes.length === 0}
                  required
                >
                  <option value="" disabled>
                    {filteredBagagistes.length === 0 ? 
                      formData.idAgence ? "Aucun bagagiste disponible dans cette agence" : "Sélectionnez d'abord une agence" 
                      : "Choisir le bagagiste"}
                  </option>
                  {filteredBagagistes.map((employe) => (
                    <option
                      key={employe ? employe.idEmploye : 0}
                      value={employe ? employe.idEmploye : 1}
                    >
                      {employe ? employe.nom + " | " + employe.prenom : ""}
                    </option>
                  ))}
                </Form.Select>
                {/* {isEditMode && !formData.idAgence && (
                  <small className="text-muted">Veuillez d'abord sélectionner une agence</small>
                )} */}
                {isEditMode && formData.idAgence && filteredBagagistes.length === 0 ? (
                  <small className="text-danger">Aucun bagagiste n'est disponible dans cette agence</small>
                ) : ''}
              </Col>
            </Form.Group>
            
            {/* SOUS AGENCE DE L'AGENCE */}
            <Form.Group as={Row} className="mb-3" controlId="">
              <Col sm="5">
                <Form.Select
                  aria-label="SousAgence"
                  value={busData ? busData.idSousAgence : formData.idSousAgence}
                  onChange={(event) => handleFieldChange(event, "idSousAgence")}
                  disabled={!isEditMode || !formData.idAgence || filteredSousAgences.length === 0}
                  required
                >
                  <option value="">
                    {filteredSousAgences.length === 0 ? "Aucune sous-agence disponible" : "Sélectionner une sous-agence"}
                  </option>
                  {filteredSousAgences.map((sousAgence) => (
                    <option
                      key={sousAgence.idSousAgence}
                      value={sousAgence.idSousAgence}
                    >
                      {sousAgence.nom} - {sousAgence.ville}
                    </option>
                  ))}
                </Form.Select>
                {isEditMode && !formData.idAgence && (
                  <small className="text-muted">Veuillez d'abord sélectionner une agence</small>
                )}
              </Col>
            </Form.Group>
            
          </Card>
          <Button type="submit" ref={saveButtonRef} style={{ display: "none" }}>
            Enregistrer
          </Button>
        </Form>
        <NotificationManager notificationQueue={notificationQueue} />
        <ConfirmYesNo
          description="Êtes-vous sûr de vouloir supprimer ce depart de bus ?"
          functionYes={handleBtnDelete}
          show={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          loading={deleteMutation.isPending}
        />
      </div>
    </div>
  );
};

export default UpdateBusDepart;
