import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { fetchDataOld } from "../../../../services/fetchData";
import Reload from '../../../utils/reload';
import { Card, Table, Button, Badge, Form, InputGroup } from 'react-bootstrap';
import DownloadIcon from '@mui/icons-material/Download';
import FilterListIcon from '@mui/icons-material/FilterList';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

// Enregistrer les composants nécessaires pour Chart.js
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const PerformanceResponsablesChart = () => {
  const [responsablesData, setResponsablesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sousAgences, setSousAgences] = useState({});
  const [agences, setAgences] = useState([]);
  const [selectedAgence, setSelectedAgence] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setLoading(true);
    
    // Récupérer la liste des agences
    fetchDataOld("all_agences", (data) => {
      if (data && Array.isArray(data)) {
        setAgences(data);
      }
      
      // Récupérer d'abord les sous-agences pour avoir leurs noms
      fetchDataOld("all_sous_agences", (data) => {
        const sousAgencesMap = {};
        if (data && Array.isArray(data)) {
          data.forEach(sa => {
            sousAgencesMap[sa.idSousAgence] = sa.nom;
          });
        }
        setSousAgences(sousAgencesMap);
        
        // Ensuite récupérer les données des responsables
        fetchDataOld("responsables_performance", setResponsablesData, setError)
          .then(() => setLoading(false))
          .catch((err) => {
            setError(err);
            setLoading(false);
          });
      }, setError);
    }, setError);
  }, []);

  // Fonction pour filtrer les responsables selon l'agence sélectionnée
  const filteredResponsables = () => {
    if (!responsablesData) return [];
    
    let filtered = [...responsablesData];
    
    // Filtre par agence
    if (selectedAgence !== 'all') {
      filtered = filtered.filter(resp => 
        resp.idAgence === parseInt(selectedAgence)
      );
    }
    
    // Filtre par recherche textuelle
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      filtered = filtered.filter(resp => 
        resp.nom.toLowerCase().includes(searchLower) || 
        resp.prenom.toLowerCase().includes(searchLower) ||
        resp.fonction.toLowerCase().includes(searchLower) ||
        resp.nom_agence.toLowerCase().includes(searchLower)
      );
    }
    
    return filtered;
  };

  if (loading) return <Reload />;
  if (error) return <p>Erreur lors du chargement des données: {error.message}</p>;
  if (!responsablesData || !responsablesData.length) return <p>Aucune donnée disponible</p>;

  // Trier les responsables par performance (revenus)
  const sortedResponsables = filteredResponsables().sort((a, b) => b.performance - a.performance);

  // Configurer les données pour le graphique - inclure seulement les responsables filtrés
  const chartData = {
    labels: sortedResponsables.slice(0, 5).map(resp => `${resp.nom} ${resp.prenom}`),
    datasets: [
      {
        label: 'Performance (FCFA)',
        data: sortedResponsables.slice(0, 5).map(resp => resp.performance),
        backgroundColor: sortedResponsables.slice(0, 5).map(resp => {
          // Couleurs différentes selon la fonction
          if (resp.fonction === 'Directeur') return 'rgba(75, 192, 192, 0.2)';
          if (resp.fonction === 'Chef d\'agence') {
            // Couleurs selon l'agence
            if (resp.nom_agence.includes('Brazzaville')) return 'rgba(54, 162, 235, 0.2)';
            if (resp.nom_agence.includes('Pointe-Noire')) return 'rgba(255, 206, 86, 0.2)';
            if (resp.nom_agence.includes('Dolisie')) return 'rgba(153, 102, 255, 0.2)';
            return 'rgba(255, 159, 64, 0.2)';
          }
          if (resp.fonction === 'Adjoint chef d\'agence') return 'rgba(255, 99, 132, 0.2)';
          return 'rgba(201, 203, 207, 0.2)';
        }),
        borderColor: sortedResponsables.slice(0, 5).map(resp => {
          // Couleurs de bordure selon la fonction
          if (resp.fonction === 'Directeur') return 'rgba(75, 192, 192, 1)';
          if (resp.fonction === 'Chef d\'agence') {
            // Couleurs selon l'agence
            if (resp.nom_agence.includes('Brazzaville')) return 'rgba(54, 162, 235, 1)';
            if (resp.nom_agence.includes('Pointe-Noire')) return 'rgba(255, 206, 86, 1)';
            if (resp.nom_agence.includes('Dolisie')) return 'rgba(153, 102, 255, 1)';
            return 'rgba(255, 159, 64, 1)';
          }
          if (resp.fonction === 'Adjoint chef d\'agence') return 'rgba(255, 99, 132, 1)';
          return 'rgba(201, 203, 207, 1)';
        }),
        borderWidth: 1,
      }
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#FFFFFF'
        }
      },
      title: {
        display: true,
        text: 'Top 5 des responsables les plus performants',
        color: '#FFFFFF',
        font: {
          size: 16
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('fr-FR', { 
                style: 'currency', 
                currency: 'XAF',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(context.parsed.y);
            }
            return label;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: '#FFFFFF',
          callback: function(value) {
            return new Intl.NumberFormat('fr-FR', { 
              style: 'currency', 
              currency: 'XAF',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }).format(value);
          }
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        }
      },
      x: {
        ticks: {
          color: '#FFFFFF'
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        }
      }
    }
  };

  // Fonction pour obtenir la couleur de fond du tableau selon l'agence et la fonction
  const getRowStyle = (responsable, index) => {
    let style = {};
    
    // Top 3 performers get highlighted
    if (index < 3) {
      style.backgroundColor = 'rgba(40, 167, 69, 0.2)';
    }
    
    // Style selon la fonction et l'agence
    if (responsable.fonction === 'Chef d\'agence') {
      if (responsable.nom_agence.includes('Brazzaville')) {
        style.backgroundColor = 'rgba(54, 162, 235, 0.1)';
      } else if (responsable.nom_agence.includes('Pointe-Noire')) {
        style.backgroundColor = 'rgba(255, 206, 86, 0.1)';
      } else if (responsable.nom_agence.includes('Dolisie')) {
        style.backgroundColor = 'rgba(153, 102, 255, 0.1)';
      }
    }
    
    return style;
  };

  // Fonction pour générer une couleur de badge basée sur la fonction
  const getBadgeVariant = (fonction) => {
    switch (fonction) {
      case 'Directeur':
        return 'primary';
      case 'Chef d\'agence':
        return 'success';
      case 'Adjoint chef d\'agence':
        return 'info';
      default:
        return 'secondary';
    }
  };

  // Fonction pour générer une couleur de badge basée sur l'agence
  const getAgenceBadgeVariant = (agence) => {
    if (agence.includes('Brazzaville')) return 'info';
    if (agence.includes('Pointe-Noire')) return 'warning';
    if (agence.includes('Dolisie')) return 'danger';
    return 'secondary';
  };

  // Fonction pour exporter en PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setTextColor(0, 0, 0);
    doc.text('Liste des Responsables et leurs Performances', 14, 15);
    
    const tableData = sortedResponsables.map((resp, index) => [
      index + 1,
      `${resp.nom} ${resp.prenom}`,
      resp.fonction,
      resp.nom_agence,
      new Intl.NumberFormat('fr-FR', { 
        style: 'currency', 
        currency: 'XAF',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(resp.performance),
      `${resp.taux_completion}%`,
      resp.nb_reservations
    ]);
    
    doc.autoTable({
      head: [['#', 'Nom', 'Fonction', 'Agence', 'Performance', 'Taux de réussite', 'Réservations']],
      body: tableData,
      startY: 20,
      theme: 'grid',
      styles: { fontSize: 8, cellPadding: 1 },
      headStyles: { fillColor: [41, 128, 185], textColor: 255 }
    });
    
    doc.save('performance_responsables.pdf');
  };

  // Fonction pour exporter en Excel
  const exportToExcel = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    
    const excelData = sortedResponsables.map((resp, index) => ({
      'Classement': index + 1,
      'Nom': `${resp.nom} ${resp.prenom}`,
      'Fonction': resp.fonction,
      'Agence': resp.nom_agence,
      'Performance (FCFA)': resp.performance,
      'Taux de réussite (%)': resp.taux_completion,
      'Nombre de réservations': resp.nb_reservations
    }));
    
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Performance');
    
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    
    // Créer un élément d'ancrage pour télécharger
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'performance_responsables' + fileExtension;
    link.click();
  };

  return (
    <Card body style={{ backgroundColor: "#192132", borderColor: "#232e45" }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="text-white">Performance des responsables d'agence</h5>
        <div>
          <Button variant="outline-light" size="sm" className="me-2" onClick={exportToPDF}>
            <DownloadIcon fontSize="small" /> PDF
          </Button>
          <Button variant="outline-light" size="sm" onClick={exportToExcel}>
            <DownloadIcon fontSize="small" /> Excel
          </Button>
        </div>
      </div>
      
      <div className="d-flex align-items-center mb-3">
        <Form.Group className="me-3" style={{ width: '250px' }}>
          <Form.Select 
            size="sm"
            value={selectedAgence} 
            onChange={(e) => setSelectedAgence(e.target.value)}
            className="bg-dark text-white border-secondary"
          >
            <option value="all">Toutes les agences</option>
            {agences.map(agence => (
              <option key={agence.idAgence} value={agence.idAgence}>
                {agence.nom}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        
        <InputGroup style={{ maxWidth: '300px' }}>
          <InputGroup.Text className="bg-dark text-white border-secondary">
            <FilterListIcon fontSize="small" />
          </InputGroup.Text>
          <Form.Control
            size="sm"
            type="text"
            placeholder="Rechercher..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="bg-dark text-white border-secondary"
          />
        </InputGroup>
        
        <Badge bg="primary" className="ms-3">
          {sortedResponsables.length} responsables
        </Badge>
      </div>
      
      <div className="mb-4">
        <Bar data={chartData} options={options} height={80} />
      </div>
      
      <div className="d-flex justify-content-between mb-3">
        <div>
          <Badge bg="primary" className="me-2">Directeur</Badge>
          <Badge bg="success" className="me-2">Chef d'agence</Badge>
          <Badge bg="info" className="me-2">Adjoint chef d'agence</Badge>
        </div>
        <div>
          <Badge bg="info" className="me-2">Brazzaville</Badge>
          <Badge bg="warning" className="me-2">Pointe-Noire</Badge>
          <Badge bg="danger">Dolisie</Badge>
        </div>
      </div>
      
      <div className="table-responsive">
        <Table striped hover variant="dark">
          <thead>
            <tr>
              <th>#</th>
              <th>Nom</th>
              <th>Fonction</th>
              <th>Agence</th>
              <th>Sous-agence</th>
              <th>Performance</th>
              <th>Taux de réussite</th>
              <th>Réservations</th>
            </tr>
          </thead>
          <tbody>
            {sortedResponsables.map((resp, index) => (
              <tr key={index} style={getRowStyle(resp, index)}>
                <td>{index + 1}</td>
                <td>{resp.nom} {resp.prenom}</td>
                <td>
                  <Badge bg={getBadgeVariant(resp.fonction)} className="me-1">
                    {resp.fonction}
                  </Badge>
                </td>
                <td>
                  <Badge bg={getAgenceBadgeVariant(resp.nom_agence)} className="me-1">
                    {resp.nom_agence}
                  </Badge>
                </td>
                <td>
                  {resp.nom_sous_agence ? (
                    <Badge bg="light" text="dark">
                      {resp.nom_sous_agence}
                    </Badge>
                  ) : (
                    <span className="text-muted">Aucune</span>
                  )}
                </td>
                <td>
                  {new Intl.NumberFormat('fr-FR', { 
                    style: 'currency', 
                    currency: 'XAF',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }).format(resp.performance)}
                </td>
                <td>
                  <div style={{ 
                    width: '100%', 
                    backgroundColor: '#343a40',
                    borderRadius: '5px',
                    overflow: 'hidden'
                  }}>
                    <div style={{ 
                      width: `${resp.taux_completion}%`, 
                      backgroundColor: resp.taux_completion > 75 ? '#28a745' : resp.taux_completion > 50 ? '#ffc107' : '#dc3545',
                      height: '10px',
                      borderRadius: '5px'
                    }} />
                  </div>
                  <span className="small">{resp.taux_completion}%</span>
                </td>
                <td>{resp.nb_reservations}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Card>
  );
};

export default PerformanceResponsablesChart;
