import React, { useCallback, useMemo, useState } from "react";
import "../../../assets/css/style_defaultWidget.css";
import "../../../pages/global/style_global.scss";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Dropdown, Form, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TableDesny from "../../customComponents/tableDesny";
import { generatePDF } from "../../../services/fetchData";

const TableAgences = () => {
  const [newRowData, setNewRowData] = useState([]);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(
    () => ({ height: "100%", width: "100%", background: "red !important" }),
    []
  );
  const [searchValue, setSearchValue] = useState("");
  
  const handleSearchChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  let dataTable = [
    {
      field: "idAgence",
      hide: true,
      editable: false,
    },
    {
      field: "nom",
      headerName: "Nom",
      editable: false,
      cellStyle: { textAlign: 'left' }
    },
    { 
      field: "ville", 
      headerName: "Ville", 
      editable: false, 
      cellStyle: function (params) {
        if (params.value === "Brazzaville") {
          return { color: "#10b981", textAlign: 'left' };
        }
        if (params.value === "Pointe-Noire") {
          return { color: "#7e91ff", textAlign: 'left' };
        }
        if (params.value === "Dolisie") {
          return { color: "#dc3545", textAlign: 'left' };
        }
        return { textAlign: 'left' };
      },
    },
    { 
      field: "adresse", 
      headerName: "Adresse", 
      editable: false,
      cellStyle: { textAlign: 'left' } 
    },
    { 
      field: "telephone", 
      headerName: "Téléphone", 
      editable: false,
      cellStyle: { textAlign: 'left' } 
    },
    { 
      field: "responsable", 
      headerName: "Responsable", 
      editable: false,
      cellStyle: { textAlign: 'left' } 
    },
    { 
      field: "nb_employes", 
      headerName: "Employés", 
      editable: false,
      cellStyle: { textAlign: 'center' } 
    },
    { 
      field: "nb_sous_agences", 
      headerName: "Sous-agences", 
      editable: false,
      cellStyle: { textAlign: 'center' } 
    },
    { 
      field: "date_creation", 
      headerName: "Date création", 
      editable: false,
      cellStyle: { textAlign: 'left' } 
    },
    { field: "cle", hide: true },
  ];
  
  return (
    <div style={containerStyle}>
      <Button
        type="button"
        onClick={() =>
          generatePDF("generate-AgencesPDF", "Liste_agences", newRowData)
        }
        style={{ float: "left", fontSize: "0.8rem" }}
      >
        <PictureAsPdfIcon /> Exporter PDF
      </Button>
      <div className="text-end pb-2">
        <InputGroup
          className="ms-auto custom-placeholder"
          style={{ width: "37%" }}
        >
          <Form.Control
            type="text"
            placeholder="Rechercher ..."
            onChange={handleSearchChange}
            className="border-none rounded-start"
            style={{ background: "", border: "solid 1px white" }}
          />

          <InputGroup.Text
            id="basic-addon2"
            style={{ background: "none", borderLeft: "none !important" }}
            className="rounded-end"
          >
            <SearchIcon className="text-light" />
          </InputGroup.Text>
          <div className="ms-2">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <MoreHorizIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item style={{ fontSize: "0.9rem" }}>
                  <Link
                    to="/update_agence/new"
                    className="text-decoration-none text-dark"
                  >
                    Ajouter une agence
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </InputGroup>
      </div>
      <TableDesny
        gridStyle={gridStyle}
        dataTable={dataTable}
        searchKeys={["nom", "ville", "adresse", "telephone", "responsable"]}
        endPoint="all_agences"
        searchValue={searchValue}
        idItemClick="idAgence"
        page="update_agence"
        onRowDataChange={(newRowData) => {
          setNewRowData(newRowData);
        }}
        clickable={true}
      />
    </div>
  );
};

export default TableAgences;
