import React, { useCallback, useMemo, useState } from "react";
import "../../../assets/css/style_defaultWidget.css";
import "../../../pages/global/style_global.scss";
import SearchIcon from "@mui/icons-material/Search";
import {Form, InputGroup } from "react-bootstrap";
import TableDesny from "../../customComponents/tableDesny";

const TableReservation = () => {
  const [newRowData, setNewRowData] = useState([]);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(
    () => ({ height: "100%", width: "100%", background: "red !important" }),
    []
  );
  const [searchValue, setSearchValue] = useState(""); // État pour stocker la valeur de recherche
  // Gestionnaire d'événements pour la modification de la valeur de recherche
  const handleSearchChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const monthNameFormatter = (date) => {
    const dateObj = new Date(date);
    const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    return `${monthNames[dateObj.getMonth()]} ${dateObj.getFullYear()}`;
  };

  let dataTable = [
    { field: 'nom_agence', rowGroup: true, hide: true }, // Groupement par agence
    {
      field: 'dateReservation',
      headerName: 'Mois',
      rowGroup: true,
      hide: true,
      valueGetter: (params) => monthNameFormatter(params.data.dateReservation) // Convertit la date en mois pour le groupement
    },
    { field: "idReservation", hide: true },
    { field: "nom_voyageur", headerName: "Nom",editable: false},
    { field: "prenom_voyageur", headerName: "Prénom",editable: false  },


    {
      field: "statut",
      headerName: "Statut",
      cellStyle: function (params) {
        if (params.value === "En cours") {
          return { backgroundColor: "#d6bd3d", color: "black", fontWeight: "500"};
        }
        if (params.value === "Terminé") {
          return { backgroundColor: "#10b981", color: "black", fontWeight: "500"};
        }
        if (params.value === "Annulé") {
          return { backgroundColor: "red", color: "black" };
        }
        return null;
      },
    },
    {
      field: "dateReservation",
      headerName: "Date de réservation",
      editable: false,
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString();
      },
    },
    {
      field: "heure_depart_bus",
      headerName: "Heure de départ",
      editable: false,
    },
    { field: "depart", headerName: "Départ", editable: false },
    { field: "arrivee", headerName: "Arrivée", editable: false },
    { field: "modele_bus", headerName: "Bus", editable: false },
    { field: "montantTotal", headerName: "Montant", editable: false },
    {
      field: "nom_agence",
      editable: false,
      headerName: "Agence",
      cellStyle: function (params) {
        if (params.value === "Agence Brazzaville") {
          return { color: "#10b981" };
        }
        if (params.value === "Agence Pointe-Noire") {
          return { color: "#7e91ff" };
        }
        if (params.value === "Agence Dolisie") {
          return { color: "#dc3545" };
        }
        return null;
      },
    },
    { field: "cle", hide: true },
  ];
  return (
    <div style={containerStyle}>
      <div className=" text-end pb-2">
        <InputGroup
          className="ms-auto  custom-placeholder"
          style={{ width: "37%" }}
        >
          <Form.Control
            type="text"
            placeholder="Rechercher ..."
            onChange={handleSearchChange}
            className="border-none rounded-start "
            style={{ background: "", border: "solid 1px white" }}
          />

          <InputGroup.Text
            id="basic-addon2"
            style={{ background: "none", borderLeft: "none !important" }}
            className="rounded-end"
          >
            <SearchIcon className="text-light" />
          </InputGroup.Text>
        </InputGroup>
      </div>
      {/* // eslint-disable-next-line */}
      <TableDesny
        gridStyle={gridStyle}
        dataTable={dataTable}
        searchKeys={[
          "nom_voyageur",
          "prenom_voyageur",
          "numero_telephone_voyageur",
          "dateReservation",
          "heure_depart_bus",
          "montantTotal",
          "nom_employe",
          "nom_agence",
        ]}
        endPoint="all_reservations"
        itemMontant="montantTotal"
        searchValue={searchValue}
        idItemClick="idReservation"
        page="update_employe"
        onRowDataChange={(newRowData) => {
          setNewRowData(newRowData);
        }}
        clickable={false}
        modal={true}
      />

    </div>
  );
};

export default TableReservation;
