import React, {useState } from "react";
import TableDepartBus from "../../components/widgets/reservation/tableDepartBus";
import Reload from "../../components/utils/reload";
import { ErrorConnexion } from "../../components/modal/error500";
import { useCustomQuery } from "../../components/utils/useCustomQuery ";

const Bus = () => {
  const [error, setError] = useState(null);
  const { data: allBus, isLoading: isLoadingAllBus } = useCustomQuery(
    ["all_bus"],
    "all_bus",
    setError
  );
  const { data: allReservations, isLoading: isLoadingAllReservation } =
    useCustomQuery(["all_reservations"], "all_reservations", setError);

  if (isLoadingAllBus || isLoadingAllReservation) return <Reload />;
  if (error) return <ErrorConnexion error={error} />;

  return (
    <div className="m-3 mt-5 pt-3">
      <p className="fw-semibold">
        <span className="text-secondary">Liste des départs des bus</span>{" "}
        <span className="text-light">&#62;</span>{" "}
        <span className="text-light">Tous les départs</span>
      </p>
      <div className="row g-0 text-center">
        <div
          className="col-sm-6 col-lg-12 col-md-12 pe-2"
          style={{ height: "80vh" }}
        >
          <TableDepartBus allBus={allBus} allReservations={allReservations} />
        </div>
      </div>
    </div>
  );
};

export default Bus;
