import React, { useEffect, useState, useRef } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Card, Dropdown, Form , Row, Col} from "react-bootstrap";
import { getElement, fetchDataOld, deleteElement, addSousAgence } from "../../services/fetchData";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationManager from "../../components/utils/notificationManger";
import Reload from "../../components/utils/reload";
import { useDeleteMutation, usePostMutation } from "../../components/utils/useCustomQuery ";
import ConfirmYesNo from "../../components/modal/confirmYesNo";

const UpdateSousAgence = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const isNew = id === "new";
  const searchParams = new URLSearchParams(location.search);
  const preselectedAgenceId = searchParams.get("idAgence");
  
  const [sousAgenceData, setSousAgenceData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [notificationQueue, setNotificationQueue] = useState([]);
  
  // Modal de confirmation de suppression
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentToDelete, setCurrentToDelete] = useState(null);
  
  const [agences, setAgences] = useState([]);
  const [employes, setEmployes] = useState([]);
  const [error, setError] = useState(null);
  
  const saveButtonRef = useRef();
  
  const initialFormData = {
    nom: "",
    ville: "",
    adresse: "",
    telephone: "",
    idAgence: preselectedAgenceId || "",
    idEmploye: "",
    idSousAgence: id === "new" ? "" : id
  };
  
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    try {
      fetchDataOld("all_agences", setAgences, setError);
      // Filtre les emplyés pour ne garder que les directeurs d'agence
      fetchDataOld("all_directeur_employes", setEmployes, setError);
    } catch (error) {
      showErrorNotification();
    }

    if (id !== "new") {
      // Correction ici - utiliser "view_sous_agences" au lieu de "sous_agence"
      getElement("view_sous_agences", "idSousAgence", id, (data) => {
        setSousAgenceData(data);
        if (data) {
          setFormData({
            ...initialFormData,
            nom: data.nom || initialFormData.nom,
            ville: data.ville || initialFormData.ville,
            adresse: data.adresse || initialFormData.adresse,
            telephone: data.telephone || initialFormData.telephone,
            idAgence: data.idAgence || initialFormData.idAgence,
            idEmploye: data.idEmploye || initialFormData.idEmploye,
            idSousAgence: id
          });
        }
      });
    } else {
      setIsEditMode(true);
    }
  }, []);

  const updateField = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setSousAgenceData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  
  const handleEditClick = () => {
    setIsEditMode(true);
  };
  
  const handleSaveClick = () => {
    setIsEditMode(false);
    showSuccessNotification();
  };
  
  const handleFieldChange = (event, fieldName) => {
    updateField(fieldName, event.target.value);
  };

  const addMutation = usePostMutation(["sous_agences"], addSousAgence);
  const deleteMutation = useDeleteMutation(["sous_agences"]);

  const handleOpenDeleteModal = (sousAgenceData) => {
    setCurrentToDelete(sousAgenceData);
    setShowConfirmModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // S'assurer que tous les champs obligatoires sont remplis
    const requiredFields = ['nom', 'ville', 'adresse', 'telephone', 'idAgence', 'idEmploye'];
    const missingFields = requiredFields.filter(field => !formData[field]);
    
    if (missingFields.length > 0) {
      setNotificationQueue([
        ...notificationQueue,
        { isSuccess: false, isError: true, isAlert: true, message: `Champs obligatoires manquants: ${missingFields.join(', ')}` },
      ]);
      return;
    }

    // Convertir idAgence et idEmploye en nombre si nécessaire
    const dataToSubmit = {
      ...formData,
      idAgence: parseInt(formData.idAgence),
      idEmploye: parseInt(formData.idEmploye),
      idSousAgence: id !== "new" ? parseInt(id) : undefined
    };

    addMutation.mutate(dataToSubmit, {
      onSuccess: () => {
        showSuccessNotification();
        setTimeout(() => {
          navigate("/agences");
        }, 700);
      },
      onError: (error) => {
        console.error("Erreur lors de la soumission:", error);
        showErrorNotification();
      },
    });
  };

  const handleBtnDelete = () => {
    if (currentToDelete) {
      deleteMutation.mutate(
        {
          tableName: "sous_agence",
          idFieldName: "idSousAgence",
          id: id,
          cle: currentToDelete.cle,
        },
        {
          onSuccess: () => {
            setShowConfirmModal(false);
            showSuccessNotification();
            setTimeout(() => {
              navigate("/agences");
            }, 700);
          },
          onError: () => {
            showErrorNotification();
          },
        }
      );
    }
  };

  const showSuccessNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: true, isError: false, isAlert: false },
    ]);
  };

  const showErrorNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: false, isError: true, isAlert: false },
    ]);
  };

  const simulateSaveButtonClick = () => {
    saveButtonRef.current.click();
  };

  if (!sousAgenceData && id !== "new") {
    return <Reload />;
  }


  return (
    <div>
      <p className="fw-semibold m-3 mt-5 pt-3">
        <span className="text-secondary">Gestion des agences</span>{" "}
        <span className="text-secondary">&#62;</span>{" "}
        <NavLink
          to="/agences"
          className="text-secondary text-decoration-none hover-link-in"
        >
          Toutes les agences
        </NavLink>
        <span className="text-light"> &#62;</span>{" "}
        <span className="text-light">
          {sousAgenceData ? sousAgenceData.nom : "Nouvelle sous-agence"}
        </span>
      </p>
      
      <div className="container-fluid my-2">
        <Form className="pt-3" onSubmit={handleSubmit}>
          <Card body>
            <div className="d-flex">
              <Card.Title className="pb-3 title">Détails de la sous-agence</Card.Title>
              {isEditMode ? (
                <Alert variant="warning" className="p-1 ms-auto fw-semibold">
                  Mode modification
                </Alert>
              ) : (
                <p></p>
              )}
              <Dropdown className="ms-auto">
                <Dropdown.Toggle id="dropdown-basic">
                  Outils <MenuIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {isEditMode ? (
                    <Dropdown.Item
                      style={{ fontSize: "0.9rem" }}
                      type="submit"
                      onClick={() => {
                        simulateSaveButtonClick();
                        handleSaveClick();
                      }}
                    >
                      Enregistrer
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      style={{ fontSize: "0.9rem" }}
                      onClick={handleEditClick}
                    >
                      Modifier
                    </Dropdown.Item>
                  )}
                  {!isNew && (
                    <Dropdown.Item
                      style={{ fontSize: "0.9rem" }}
                      onClick={() => handleOpenDeleteModal(sousAgenceData)}
                    >
                      Supprimer
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            
            <p className="" style={{ fontSize: "0.8rem !important" }}>
              Le formulaire d'informations de la sous-agence.
            </p>
            <hr />
            
            <Card.Title className="pb-1 title">Informations générales</Card.Title>
            <p className="fw-semibold">
              Veuillez fournir les informations générales de la sous-agence.
            </p>
            
                <Form.Group as={Row} className=" mt-4" controlId="adress">
                <Form.Label column sm="2" lg="2" md="2" className="mb-4">
                    Nom de la sous-agence
                </Form.Label>
                
                <Col sm="5">
                  <Form.Control
                    type="text"
                    name="nom"
                    value={sousAgenceData ? sousAgenceData.nom : formData.nom}
                    onChange={(e) => handleFieldChange(e, "nom")}
                    disabled={!isEditMode}
                    required
                  />
                </Col>

                <Col sm="5">
                  <Form.Select
                    name="ville"
                    value={sousAgenceData ? sousAgenceData.ville : formData.ville}
                    onChange={(e) => handleFieldChange(e, "ville")}
                    disabled={!isEditMode}
                    required
                  >
                    <option value="">Sélectionnez une ville</option>
                    <option value="Brazzaville">Brazzaville</option>
                    <option value="Pointe-Noire">Pointe-Noire</option>
                    <option value="Dolisie">Dolisie</option>
                  </Form.Select>
                </Col>
            </Form.Group>
            
            <Form.Group as={Row}  controlId="adress">
            <Form.Label column sm="2" lg="2" md="2" >                    
                    Adresse</Form.Label>
                    <Col sm="5">

                  <Form.Control
                    type="text"
                    name="adresse"
                    value={sousAgenceData ? sousAgenceData.adresse : formData.adresse}
                    onChange={(e) => handleFieldChange(e, "adresse")}
                    disabled={!isEditMode}
                    required
                  />
                </Col>
                <Col sm="5">


                <Form.Group as={Row} className="mb-4" controlId="adress">
                <Form.Label column sm="2" lg="2" md="2" className="mb-4">                    
                Téléphone</Form.Label>
                <Col sm="5">
                  <Form.Control
                    type="text"
                    name="telephone"
                    value={sousAgenceData ? sousAgenceData.telephone : formData.telephone}
                    onChange={(e) => handleFieldChange(e, "telephone")}
                    disabled={!isEditMode}
                    required
                  />
                  </Col>
                </Form.Group>
                </Col>
                </Form.Group>

            

                <Form.Group as={Row}  controlId="adress">
                <Form.Label column sm="2" lg="2" md="2" className="mb-4">                    
                  Agence principale
                  </Form.Label>
                  <Col sm="5">
                  <Form.Select
                    name="idAgence"
                    value={sousAgenceData ? sousAgenceData.idAgence : formData.idAgence}
                    onChange={(e) => handleFieldChange(e, "idAgence")}
                    disabled={!isEditMode}
                    required
                  >
                    <option value="">Sélectionnez une agence</option>
                    {agences.map((agence) => (
                      <option key={agence.idAgence} value={agence.idAgence}>
                        {agence.nom} - {agence.ville}
                      </option>
                    ))}
                  </Form.Select>
                  </Col>
 
                <Col sm="5">
                <Form.Group as={Row}  controlId="adress">
                <Form.Label column sm="2" lg="2" md="2" className="mb-4">                    
                    Responsable</Form.Label>
                    <Col sm="5">
                  <Form.Select
                    name="idEmploye"
                    value={sousAgenceData ? sousAgenceData.idEmploye : formData.idEmploye}
                    onChange={(e) => handleFieldChange(e, "idEmploye")}
                    disabled={!isEditMode}
                    required
                  >
                    <option value="">Sélectionnez un responsable</option>
                    {employes.map((employe) => (
                      <option key={employe.idEmploye} value={employe.idEmploye}>
                        {employe.nom} {employe.prenom} - {employe.fonction}
                      </option>
                    ))}
                  </Form.Select>
                  </Col>
                </Form.Group>
                </Col>
                </Form.Group>



          </Card>
          <Button type="submit" ref={saveButtonRef} style={{ display: "none" }}>
            Enregistrer
          </Button>
        </Form>
        <NotificationManager notificationQueue={notificationQueue} />
        <ConfirmYesNo
          description="Êtes-vous sûr de vouloir supprimer cette sous-agence ?"
          functionYes={handleBtnDelete}
          show={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          loading={deleteMutation.isPending}
        />
      </div>
    </div>
  );
};

export default UpdateSousAgence;
