import React, { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import Topbar from "./pages/global/Topbar";
import Leftbar from "./pages/global/Leftbar";
import ParDefaut from "./pages/dashboard"; // Créez votre propre composant de page par défaut
import Management from "./pages/dashboard/management"; // Créez votre propre composant de page de gestion
import Analytics from "./pages/dashboard/analytics"; // Créez votre propre composant de page d'analyse
import Login from "./components/utils/login";
import axios from "axios";
import AllEmployers from "./pages/list_employees/all_employers";
import NoMobile from "./pages/global/noMobile";
import ActivityInsert from "./components/widgets/dashboard/insert/activityInsert";
import UpdateEmploye from "./components/widgets/list_employees/action/updateEmploye";
import Reload from "./components/utils/reload";
import AllReservations from "./pages/reservation/all_reservations";
import Salaries from "./pages/list_employees/salaries";
import Bus from "./pages/bus/bus";
import Voyageurs from "./pages/active/voyageurs";
import UpdateBusDepart from "./components/widgets/reservation/action/updateBusDepart";
import UpdateVoyageur from "./components/widgets/actives/action/update_voyageur";
import Colis from "./pages/active/colis";
import AllBus from "./pages/active/Allbus";
import Profil from "./pages/setting/profil";
import UpdateBus from "./components/widgets/actives/action/updateBus";
import UpdateUser from "./pages/setting/updateUser";
import UpdateSousAgence from "./pages/agences/UpdateSousAgence";
import UpdateAgence from "./pages/agences/UpdateAgence";
import Agences from "./pages/agences/Agences";
import AgencesStatistiques from "./pages/dashboard/AgencesStatistiques";



function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 3,
      },
    },
  });
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null); 
  const [loading, setLoading] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_URL;


  const handleLogin = (userData) => {
    setIsLoggedIn(true);
    setUser(userData);
  };
  const handleLogout = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${API_BASE_URL}/config/login.php?endpoint=deconnexion`,
        {},
        {}
      );
      document.cookie = "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
      queryClient.clear();
      setIsLoggedIn(false);
      setLoading(false);
      setUser(null);
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
      setLoading(false);
    }
  };
  const userRole = user ? user.role : "";
  return (
    <QueryClientProvider client={queryClient}>
    <div className="container-fluid ">
      <NoMobile />
        <div className="row phone-none">
          {isLoggedIn ? (
            <>
              <div className="col-12 p-0 ">
                <Topbar handleLogout={handleLogout} user={user} loading={loading} />
              </div>
              <Leftbar user={user} />
              <div
                className="col p-0 bg-color-blue-in"
                style={{ overflow: "auto", height: "100vh" }}
              >
                <Routes>
                  <Route path="/"  element={userRole === "Admin" || userRole ==="Dir" ? (<ParDefaut user={user}/>) : (<Navigate to="/reservation" />)}/>
                  <Route path="/management" element={userRole === "Admin" || userRole ==="Dir" ? (<Management user={user}/>) :(handleLogout)}/>
                  <Route path="/salaires"  element={userRole === "Admin" || userRole ==="Dir" ? (<Salaries user={user}/>): (handleLogout)}/>
                  <Route path="/analytics"  element={userRole === "Admin" || userRole ==="Dir"  ? (<Analytics user={user}/> ) : (handleLogout)}/>
                  {/* <Route path="/finances_agences"  element={userRole === "Admin" || userRole ==="Dir"  ? ( ) : (handleLogout)}/> */}

                  <Route path="/list_employees"  element={userRole === "Admin" || userRole ==="Dir"  ? (<AllEmployers user={user}/> ) : (handleLogout)}/>
                  <Route path="/reload"  element={<Reload />} />
                  <Route path="/activite"  element={userRole === "Admin" || userRole ==="Dir"  ? (<ActivityInsert user={user}/> ) : (handleLogout)}/>
                  <Route path="/reservation"  element={(userRole==="Admin" || userRole ==="Dir"  || userRole ==="Empl") ?(<AllReservations user={user}/> ) : (handleLogout)}/>
                  <Route path="/voyageurs"  element={(userRole==="Admin" || userRole ==="Dir"  || userRole ==="Empl") ?(<Voyageurs user={user}/> ) : (handleLogout)}/>
                  <Route path="/colis"  element={(userRole==="Admin" || userRole ==="Dir" || userRole ==="Empl") ?(<Colis user={user}/> ) : (handleLogout)}/>
                  <Route path="/depart_bus"  element={(userRole==="Admin" || userRole ==="Dir"  || userRole ==="Empl") ?(<Bus user={user}/> ) : (handleLogout)}/>
                  <Route path="/bus"  element={(userRole==="Admin" || userRole ==="Dir"  || userRole ==="Empl") ?(<AllBus user={user}/> ) : (handleLogout)}/>
                  {/* Update des données */}
                  <Route path="/update_employe/:id" element={userRole === "Admin" || userRole ==="Dir"  ? ( <UpdateEmploye />) : (handleLogout)}/>
                  <Route path="/update_busDepart/:id" element={(userRole==="Admin" || userRole ==="Dir"  || userRole ==="Empl") ? (<UpdateBusDepart  />) : (handleLogout)} />
                  <Route path="/update_voyageur/:id" element={(userRole==="Admin" || userRole ==="Dir"  || userRole ==="Empl") ? (<UpdateVoyageur  />) : (handleLogout)} />
                  <Route path="/update_colis/:id" element={(userRole==="Admin" || userRole ==="Dir"  || userRole ==="Empl") ? (<UpdateVoyageur  />) : (handleLogout)} />
                  <Route path="/update_bus/:id" element={(userRole==="Admin" || userRole ==="Dir"  || userRole ==="Empl") ? (<UpdateBus  />) : (handleLogout)} />
                  {/* PROFILE ET SETTINGS */}
                  <Route path="/profil" element={(userRole==="Admin" || userRole ==="Dir"  || userRole ==="Empl") ?(<Profil user={user}/> ) : (handleLogout)}/>
                  <Route path="/update_user/:id" element={(userRole==="Admin") ?(<UpdateUser user={user}/> ) : (handleLogout)}/>
                  {/* Agences et sous-agences */}
                  <Route path="/agences" element={<Agences />} />
                  <Route path="/update_agence/:id" element={<UpdateAgence />} />
                  <Route path="/update_sous_agence/:id" element={<UpdateSousAgence />} />
                  <Route path="/statistics_agences" element={userRole === "Admin" || userRole ==="Dir" ? (<AgencesStatistiques />) : (handleLogout)}/>
                </Routes>
              </div>
            </>
          ) : (
            <Routes>
              <Route path="/" element={<Login handleLogin={handleLogin} />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          )}
        </div>
    </div>
    </QueryClientProvider>
  );
}

export default App;
