import {
  React,
  useState,
  Button,
  Card,
  ProgressBar,
  formatNumber,
  MoisActuel,
} from "../../../services/importsDefaultWidget";
import Reload from "../../utils/reload";
import { useCustomQuery } from "../../utils/useCustomQuery ";
import { ErrorConnexion } from "../../modal/error500";

const DefaultWidget = () => {
  const [error, setError] = useState(null);
  const { data: nbVoyageurs, isLoading: isLoadingVoyageurs } = useCustomQuery(['voyageurs_par_mois'], "voyageurs_par_mois", setError);
  const { data: revenusPN, isLoading: isLoadingRevenusPN } = useCustomQuery(['revenus_par_mois_pn'], "revenus_par_mois_pn", setError);
  const { data: revenusBZ, isLoading: isLoadingRevenusBZ } = useCustomQuery(['revenus_par_mois_bz'], "revenus_par_mois_bz", setError);
  const { data: revenusDOL, isLoading: isLoadingRevenusDOL } = useCustomQuery(['revenus_par_mois_dol'], "revenus_par_mois_dol",setError);
  const { data: AllEmployers, isLoading: isAllEmployers } = useCustomQuery(['nbAll_employes'], "nbAll_employes");
  const { data: reservationMois, isLoading: isreservationMois } = useCustomQuery(['nbReservation_par_mois_agence'], "nbReservation_par_mois_agence", setError);
  const { data: reservationTotalMois, isLoading: isreservationTotalMois } = useCustomQuery(['nbTotalReservation_par_mois_agence'], "nbTotalReservation_par_mois_agence", setError);
  

  const StyleCard = {
    width: "100%",
    border: "none",
    height: "20rem",
    background: "#232e45",
  };
  const buttonMensuel = (
    <div className="text-end">
      <Button
        style={{
          fontSize: "0.8rem",
        }}
        variant="primary"
      >
        Mensuel ( {<MoisActuel />} )
      </Button>{" "}
    </div>
  );

  if(isLoadingVoyageurs || isLoadingRevenusPN || isLoadingRevenusBZ || isLoadingRevenusDOL || isAllEmployers || isreservationMois || isreservationTotalMois) return <Reload />;
  if (error) return <ErrorConnexion error={error} />;

  // AJUSTE
  return (
    <div className="row">
      <div className="col-lg-6 col-md-6">
        <Card style={StyleCard}>
          <Card.Body className=" text-white">
            {buttonMensuel}
            <div className="pb-5">
              <div>
                <h3 className="fs-1">
                  {nbVoyageurs ? nbVoyageurs.nombre_de_voyageurs : 0}
                </h3>
                <p>
                  Nombre de voyageurs ({" "}
                  {nbVoyageurs ? nbVoyageurs.nom_mois : <MoisActuel />} )
                </p>
              </div>
            </div>

            <div>
              <div className="d-flex pt-5">
                <p className="w-100">Objectif / 3000</p>
                <p>
                  {(
                    (parseInt(
                      nbVoyageurs ? nbVoyageurs.nombre_de_voyageurs : 0
                    ) /
                      3000) *
                    100
                  ).toFixed(0)}
                  %
                </p>
              </div>
              <ProgressBar
                style={{ height: "10px" }}
                now={parseInt(
                  nbVoyageurs ? nbVoyageurs.nombre_de_voyageurs : 0
                )}
                max={3000}
              />
            </div>
          </Card.Body>
        </Card>
      </div>

      <div className="col-lg-6 col-md-6">
        <Card style={StyleCard}>
          <Card.Body className=" text-white">
            {buttonMensuel}
            <div className="pb-2 mt-2 d-flex flex-column fw-semibold">
              <div style={{ background: "#323E59" }} className=" rounded-3">
                <p className="d-flex pe-3 ps-3 pt-1 mb-0">
                  <span className="me-auto fs-5">
                    {formatNumber(revenusBZ ? revenusBZ.montant_total : 0.0)}
                  </span>{" "}
                  <span className="text-success">FCFA</span>
                </p>
                <p
                  className="pe-3 ps-3 pt-3 mb-0 pb-1"
                  style={{ fontSize: "0.8rem" }}
                >
                  Revenu Brazzaville
                </p>
              </div>
              <div style={{ background: "#323E59" }} className="mt-3 rounded-3">
                <p className="d-flex pe-3 ps-3 pt-1 mb-0">
                  <span className="me-auto fs-5">
                    {formatNumber(revenusPN ? revenusPN.montant_total : 0.0)}
                  </span>{" "}
                  <span className="" style={{ color: "#4D51D1" }}>
                    FCFA
                  </span>
                </p>
                <p
                  className="pe-3 ps-3 pt-3 mb-0 pb-1"
                  style={{ fontSize: "0.8rem" }}
                >
                  Revenu Pointe Noire
                </p>
              </div>
              <div style={{ background: "#323E59" }} className="mt-3 rounded-3">
                <p className="d-flex pe-3 ps-3 pt-1 mb-0">
                  <span className="me-auto fs-5">
                    {formatNumber(revenusDOL ? revenusDOL.montant_total : 0.0)}
                  </span>{" "}
                  <span className="text-danger">FCFA</span>
                </p>
                <p
                  className="pe-3 ps-3 pt-3 mb-0 pb-1"
                  style={{ fontSize: "0.8rem" }}
                >
                  Revenu Dolisie
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>

      <div className="col-lg-6 col-md-6 mt-3">
        <Card style={StyleCard}>
          <Card.Body className=" text-white">
            <div className="fw-semibold">
              <p className="fs-1">{AllEmployers ? AllEmployers.total : 0}</p>
              <p>Employé(s)</p>
            </div>
            <div className="py-5">
              <p className="d-flex">
                <span className="me-auto">Total employé(s) Brazzaville</span>
                <span className="fw-semibold text-success">
                  {AllEmployers ? AllEmployers.brazzaville_count : 0}
                </span>
              </p>
              <p className="d-flex">
                <span className="me-auto">Total employé(s) Dolisie</span>
                <span className="fw-semibold text-danger">
                  {AllEmployers ? AllEmployers.dolisie_count : 0}
                </span>
              </p>
              <p className="d-flex">
                <span className="me-auto">Total employé(s) Pointe-Noire</span>
                <span className="fw-semibold" style={{ color: "#4D51D1" }}>
                  {AllEmployers ? AllEmployers.pointe_noire_count : 0}
                </span>
              </p>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="col-lg-6 col-md-6 mt-3">
        <Card style={StyleCard}>
          <Card.Body className=" text-white">
            {buttonMensuel}
            <div className="pb-4">
              <div>
                <h3 className="fs-1">
                  {reservationTotalMois
                    ? reservationTotalMois.nb_reservations
                    : 0}
                </h3>
                <p>
                  Nombre de réservation ({" "}
                  {reservationTotalMois && <MoisActuel />} )
                </p>
              </div>
            </div>
            <div className="pt-3">
              {reservationMois.map((reservation) => (
                <p className="d-flex" key={reservation.idReservation}>
                  <span className="me-auto">
                    Total réservation(s) {reservation.nom_agence}
                  </span>
                  <span className="fw-semibold text">
                    {reservation.nombre_reservations_mois}
                  </span>
                </p>
              ))}
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default DefaultWidget;
