import React, { useEffect, useMemo, useRef, useState } from "react";
import "../../assets/css/style_defaultWidget.css";
import {
  Button,
  Col,
  Form,
  Row,
  Modal,
  InputGroup,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import {
  getItemProce,
  addReservation,
  getElement,
  generatePDF,
  fetchDataOld,
  generateWordDocument,
} from "../../services/fetchData";
import NotificationManager from "../utils/notificationManger";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useParams } from "react-router-dom";
import { dateFormated, timeFormated } from "../utils/formatedDate";
import { useDeleteMutation, usePostMutation } from "../utils/useCustomQuery ";
import ConfirmYesNo from "../../components/modal/confirmYesNo";

const ModalReservation = ({ show, onClose, idVoyageur, dataReservation, voyageur }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [newRowData, setNewRowData] = useState([]);
  const [setError] = useState(null);
  const [allBusDepart, setAllBusDepart] = useState([]);
  const [allPlaces, setAllPlaces] = useState([]);
  const [sousAgences, setSousAgences] = useState([]);
  const [filteredSousAgences, setFilteredSousAgences] = useState([]);

  // Modal de confirmation de suppression
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentToDelete, setCurrentToDelete] = useState(null);

  const montantTotal = useRef(null);
  const dataField = {
    idBusdepart: 0,
    idBus: 0,
    idPlace: 0,
    idAgence: 0,
    idSousAgence: 0,
    idVoyageur: id,
    idReservation: dataReservation ? dataReservation.idReservation : 0,
    dateReservation: "",
    heureReservation: "",
    depart: "",
    arrivee: "",
    montantTotal: "",
    montantPartenaire : "",
    montant: "",
    description: "",
    statut: "",
    nbColis : 0,
    nbKilos : 0,
    nom_agence: "Choisir l'agence",
  };

  const initialData = useMemo(
    () => ({
      ...dataField,
      ...(dataReservation || {}),
    }),
    [dataField, dataReservation]
  );

  const handleClose = () => {
    setAllData(dataField);
    show = false;
  };

  const [data, setAllData] = useState(initialData);
  const [notificationQueue, setNotificationQueue] = useState([]);
  const [placeInfo, setPlaceInfo] = useState(null);

  useEffect(() => {
    if (dataReservation) {
      setAllData((prevData) => ({ ...prevData, ...dataReservation }));
      getElement("places", "idPlace", dataReservation.idPlace, (place) => {
        setPlaceInfo(`💺 ${place.numero}`);
      });
    }
  }, [dataReservation]);

  useEffect(() => {
    fetchDataOld("all_busDepart", setAllBusDepart, setError);
    fetchDataOld("all_sous_agences", setSousAgences, setError);
    if (data.idBusdepart && data.idBus) {
      getItemProce(data.idBusdepart, data.idBus, (items) => {
        setAllPlaces(items);
      });
      
      // Recherche des informations du départ de bus sélectionné
      const selectedBusDepart = allBusDepart.find(
        (busDepart) => busDepart.idBusDepart === parseInt(data.idBusdepart)
      );
      
      // Si le bus départ a une sous-agence associée, la préremplir
      if (selectedBusDepart && selectedBusDepart.idSousAgence) {
        setAllData(prevData => ({
          ...prevData,
          idSousAgence: selectedBusDepart.idSousAgence
        }));
      }
    }
    if (data.montant || data.montantPartenaire) {
      setAllData((prevData) => ({
        ...prevData,
        montantTotal:
          (prevData.montant || 0) - (prevData.montantPartenaire || 0),
      }));
    }
  }, [
    data.idBusdepart,
    data.idBus,
    data.montant,
    data.montantPartenaire,
  ]);

  // Effet pour filtrer les sous-agences en fonction de l'agence sélectionnée
  useEffect(() => {
    if (data.idAgence) {
      const agenceId = parseInt(data.idAgence);
      const filtered = sousAgences.filter(sa => sa.idAgence === agenceId);
      setFilteredSousAgences(filtered);
    } else {
      setFilteredSousAgences([]);
    }
  }, [data.idAgence, sousAgences]);

  const updateField = (fieldName, value) => {
    const idBusDepart = value;
    const selectedBusDepart = allBusDepart.find(
      (busDepart) => busDepart.idBusDepart === parseInt(idBusDepart)
    );
    setAllData((prevData) => ({
      ...prevData,
      [fieldName]: value,
      ...(selectedBusDepart
        ? {
            idBus: selectedBusDepart.idBus,
            depart: selectedBusDepart.depart,
            arrivee: selectedBusDepart.arrive,
            heureReservation: selectedBusDepart.heureDepart,
            dateReservation: selectedBusDepart.dateDepart,
            idAgence: selectedBusDepart.idAgence,
            nom_agence: selectedBusDepart.nom_agence,
            idSousAgence: selectedBusDepart.idSousAgence || prevData.idSousAgence,
          }
        : {}),
    }));
  };

  const handleFieldChange = (event, fieldName) => {
    updateField(fieldName, event.target.value);
  };

  const addMutationReservation = usePostMutation(["reservation"], addReservation);
  const deleteMutationReservation = useDeleteMutation(["reservation"]);

  const handleOpenDeleteModal = (data) => {
    setCurrentToDelete(data);
    setShowConfirmModal(true);
  };

  const handleBtnDelete = () => {
    if (currentToDelete) {
      deleteMutationReservation.mutate({
        tableName: "reservations",
        idFieldName: "idReservation",
        id: currentToDelete.idReservation,
        cle: currentToDelete.cle,
      }, {
        onSuccess: () => {
          setShowConfirmModal(false);
          // showSuccessNotification();
        },
        onError: () => {
          showErrorNotification();
        },
      });
    }
  };
  

  const handleReservationSubmit = async (e) => {
    e.preventDefault();
    addMutationReservation.mutate(data, {
      onSuccess: () => {
          onClose();
      },
      onError: () => {
        showErrorNotification();
      },
    });
  };
  
  const showSuccessNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: true, isError: false, isAlert: false },
    ]);
  };

  const showErrorNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: false, isError: true, isAlert: false },
    ]);
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        centered
        className="modal-xl"
      >
        <Form onSubmit={handleReservationSubmit}>
          <Modal.Header>
            {dataReservation ? (
              <Modal.Title>
                <InsertDriveFileIcon />{" "}
                <span className="text-secondary">
                  Réservation n° {dataReservation.idReservation} - {voyageur.nom_voyageur} {voyageur.prenom_voyageur}
                </span>
              </Modal.Title>
            ) : (
              <Modal.Title>Nouvelle réservation</Modal.Title>
            )}
            <Dropdown className="ms-auto">
              <Dropdown.Toggle id="dropdown-basic">
                Outils <MenuIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu>
              <Dropdown.Item
                  style={{ fontSize: "0.9rem" }}
                  onClick={() =>generatePDF("generate-BilletPDF", "Billet", data)}>
                    Générer Etiquette bagage
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ fontSize: "0.9rem" }}
                  onClick={() =>generateWordDocument("generate-BilletPDF", "Billet", data)}>
                  Générer billet
                </Dropdown.Item>
                {dataReservation && (
                  <Dropdown.Item
                    style={{ fontSize: "0.9rem" }}
                    onClick={() => {
                      handleOpenDeleteModal(data);
                      onClose();
                    }}
                  >
                    Supprimer
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group className="mb-2" as={Col}>
                <Form.Select
                  required
                  onChange={(e) => {
                    handleFieldChange(e, "idBusdepart");
                  }}
                  value={data.idBusdepart}
                >
                  <option value="0" disabled selected>
                    Départ de bus
                  </option>
                  {allBusDepart.slice(0,10).map((busDepart) => (
                    <option
                      key={busDepart ? busDepart.idBusDepart : 0}
                      value={busDepart ? busDepart.idBusDepart : 1}
                    >
                      🚏{" "}
                      {busDepart
                        ? `${busDepart.depart} - ${busDepart.arrive} ⏱️${timeFormated(busDepart.heureDepart)} ${dateFormated(busDepart.dateDepart)} 🚍${busDepart.immatriculation}`
                        : ""}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" as={Col}>
                <Form.Select
                  onChange={(e) => {
                    handleFieldChange(e, "idPlace");
                  }}
                  required={true}
                >
                  <option value="" disabled selected={!dataReservation}>
                    Numéro de place
                  </option>
                  {data.idPlace && (
                    <option selected={dataReservation} disabled value={data.idPlace}>
                      {placeInfo}
                    </option>
                  )}

                  {allPlaces.map((place) => (
                    <option
                      required={true}
                      key={place ? place.idPlace : 0}
                      value={place ? place.idPlace : 1}
                      disabled={place.statut !== "Libre"}
                      className={
                        place.statut !== "Libre"
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      💺 {place ? place.numero : ""}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Control
                  type="date"
                  placeholder="Date de réservation"
                  value={data.dateReservation}
                  onChange={(e) => {
                    handleFieldChange(e, "dateReservation");
                  }}
                  disabled={dataReservation}
                  required
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control
                  type="time"
                  placeholder="Heure de départ"
                  value={data.heureReservation}
                  onChange={(e) => {
                    handleFieldChange(e, "heureReservation");
                  }}
                  disabled={dataReservation}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control
                  type="text"
                  placeholder="Départ"
                  value={data.depart}
                  onChange={(e) => {
                    handleFieldChange(e, "depart");
                  }}
                  required
                  disabled={dataReservation}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control
                  type="text"
                  placeholder="Arrivée"
                  value={data.arrivee}
                  onChange={(e) => {
                    handleFieldChange(e, "arrivee");
                  }}
                  disabled={dataReservation}
                />
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group className="mb-2" as={Col}>
                <Form.Select
                  onChange={(e) => {
                    handleFieldChange(e, "statut");
                  }}
                  required={true}
                  value={data.statut}
                >
                  <option value="" disabled selected>
                    Statut
                  </option>
                  <option value="En cours">En cours</option>
                  <option value="Terminé">Terminé</option>
                  <option value="Annulé">Annulé</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-2" as={Col}>
                <Form.Control
                  type="text"
                  placeholder="Montant reservation"
                  required={true}
                  value={data.montant}
                  onChange={(e) => {
                    handleFieldChange(e, "montant");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-2" as={Col}>
                <Form.Control
                  type="text"
                  placeholder="Montant partenaire"
                  value={data.montantPartenaire}
                  onChange={(e) => {
                    handleFieldChange(e, "montantPartenaire");
                  }}
                  required={true}
                />
              </Form.Group>
              <Form.Group className="mb-2" as={Col}>
                <Form.Control
                  ref={montantTotal}
                  type="text"
                  placeholder="Montant total"
                  required
                  disabled
                  value={data.montant ? (
                    data.montant - (data.montantPartenaire ? data.montantPartenaire : 0)
                  ).toLocaleString() : 0}
                  onChange={(e) => {
                    handleFieldChange(e, "montantTotal");
                  }}
                />
              </Form.Group>
            </Row>
    
            <Form.Group className="mb-3" as={Col}>
              <Form.Select
                onChange={(e) => {
                  handleFieldChange(e, "idAgence");
                }}
                value={data.idAgence}
                required
                disabled={dataReservation}
                >
                <option value="" disabled>
                  {" "}
                  {data ? data.nom_agence : ""}{" "}
                </option>
                <option value="1">Agence Brazzaville</option>
                <option value="2">Agence Pointe-Noire</option>
                <option value="3">Agence Dolisie</option>
              </Form.Select>
            </Form.Group>
            
            {/* Ajout du sélecteur de sous-agence */}
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Sous-agence</Form.Label>
              <Form.Select
                onChange={(e) => {
                  handleFieldChange(e, "idSousAgence");
                }}
                value={data.idSousAgence || ""}
                disabled={!data.idAgence || dataReservation}
              >
                <option value="">Sélectionner une sous-agence</option>
                {filteredSousAgences.map((sousAgence) => (
                  <option
                    key={sousAgence.idSousAgence}
                    value={sousAgence.idSousAgence}
                  >
                    {sousAgence.nom} - {sousAgence.ville}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            
            <Form.Group className="mb-3 d-flex" as={Col}>
              <Form.Label className=" me-auto">Nombre de colis (5 max)</Form.Label>
              <Form.Control
                type="number"
                className="w-25 me-auto"
                placeholder="Nombre de colis"
                onChange={(e) => {
                  handleFieldChange(e, "nbColis");
                }}
                value={data.nbColis}
              />
               <Form.Label className="ms-5 ms-auto">Nombre de kilos (23kg max)</Form.Label>
              <Form.Control
                type="number"
                className="w-25 ms-auto"
                placeholder="Nombre de kilos"
                onChange={(e) => {
                  handleFieldChange(e, "nbKilos");
                }}
                value={data.nbKilos}
              />
            </Form.Group>
            <div sm="12" lg="4" md="12" className="pb-4">
              <InputGroup>
                <InputGroup.Text>Colis</InputGroup.Text>
                <Form.Control
                  onChange={(e) => {
                    handleFieldChange(e, "description");
                  }}
                  value={data.description}
                  as="textarea"
                  aria-label="With textarea"
                  rows={5}
                />
              </InputGroup>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Enrégistré {addMutationReservation.isPending && <Spinner animation="border" size="sm" /> }
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                handleClose();
                onClose();
              }}
            >
              Fermer
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <NotificationManager notificationQueue={notificationQueue} />
      <ConfirmYesNo
        description="Êtes-vous sûr de vouloir supprimer cette réservation ?"
        functionYes={handleBtnDelete}
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        loading={deleteMutationReservation.isPending }
      />
    </div>
  );
};

export default ModalReservation;