import React, { useState, useEffect } from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { fetchDataOld } from "../../../../services/fetchData";
import Reload from '../../../utils/reload';
import { Card, Row, Col, Badge, Alert } from 'react-bootstrap';

// Enregistrer les composants nécessaires pour Chart.js
Chart.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const RessourcesHumainesChart = () => {
  const [personnelData, setPersonnelData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    console.log("Chargement des données RH...");
    
    // Utilisation d'une fonction asynchrone pour le chargement des données
    const loadData = async () => {
      try {
        // Appel API direct avec gestion améliorée des erreurs
        const response = await fetch(`${process.env.REACT_APP_API_URL}/index.php?endpoint=personnel_stats`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*\=\s*([^;]*).*$)|^.*$/, "$1")}`
          }
        });
        
        if (!response.ok) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }
        
        const rawData = await response.json();
        console.log("Données RH brutes reçues:", rawData);
        
        // Vérification et nettoyage des données
        const cleanData = {
          distribution: Array.isArray(rawData.distribution) ? rawData.distribution : [],
          chauffeurs: Array.isArray(rawData.chauffeurs) ? rawData.chauffeurs : [],
          sous_effectif: Array.isArray(rawData.sous_effectif) ? rawData.sous_effectif : []
        };
        
        console.log("Données RH nettoyées:", cleanData);
        setPersonnelData(cleanData);
        setLoading(false);
      } catch (err) {
        console.error("Erreur lors du chargement des données RH:", err);
        // Fournir des données par défaut en cas d'erreur pour éviter les crashs
        setPersonnelData({
          distribution: [],
          chauffeurs: [],
          sous_effectif: []
        });
        setError(err);
        setLoading(false);
      }
    };
    
    loadData();
  }, []);

  if (loading) return <Reload />;
  if (error) return <Alert variant="danger">Erreur lors du chargement des données: {error.message}</Alert>;
  if (!personnelData) return <Alert variant="warning">Aucune donnée disponible</Alert>;

  // Simplifier la vérification des données pour éviter les erreurs
  const hasDistribution = personnelData && Array.isArray(personnelData.distribution) && personnelData.distribution.length > 0;
  const hasChauffeurs = personnelData && Array.isArray(personnelData.chauffeurs) && personnelData.chauffeurs.length > 0;
  const hasSousEffectif = personnelData && Array.isArray(personnelData.sous_effectif) && personnelData.sous_effectif.length > 0;

  // Distribution des employés par fonction
  const distributionData = {
    labels: hasDistribution ? personnelData.distribution.map(item => item.fonction) : [],
    datasets: [
      {
        label: 'Nombre d\'employés',
        data: hasDistribution ? personnelData.distribution.map(item => item.count) : [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const distributionOptions = {
    responsive: true,
    maintainAspectRatio: false, // Permet au graphique de ne pas maintenir un ratio aspect fixe
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 0,
        bottom: 0
      }
    },
    plugins: {
      legend: {
        position: 'right',
        align: 'center',
        labels: {
          color: '#FFFFFF',
          boxWidth: 15,
          padding: 15,
          font: {
            size: 12
          }
        }
      },
      title: {
        display: true,
        text: 'Répartition du personnel',
        color: '#FFFFFF',
        font: {
          size: 16
        },
        padding: {
          top: 10,
          bottom: 20
        }
      }
    }
  };

  // Chauffeurs actifs par agence
  const chauffeursData = {
    labels: hasChauffeurs ? personnelData.chauffeurs.map(item => item.nom_agence) : [],
    datasets: [
      {
        label: 'Chauffeurs actifs',
        data: hasChauffeurs ? personnelData.chauffeurs.map(item => {
          // Si actifs n'existe pas ou est null, utiliser 0
          return item.actifs !== undefined ? parseInt(item.actifs) || 0 : item.total || 0;
        }) : [],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Chauffeurs inactifs',
        data: hasChauffeurs ? personnelData.chauffeurs.map(item => {
          // Si inactifs n'existe pas ou est null, utiliser 0
          return item.inactifs !== undefined ? parseInt(item.inactifs) || 0 : 0;
        }) : [],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      }
    ],
  };

  const chauffeursOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#FFFFFF'
        }
      },
      title: {
        display: true,
        text: 'Chauffeurs par statut et par agence',
        color: '#FFFFFF',
        font: {
          size: 16
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: '#FFFFFF'
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        }
      },
      x: {
        ticks: {
          color: '#FFFFFF'
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        }
      }
    }
  };

  return (
    <div>
      <Row className="mb-4">
        <Col md={6}>
          <Card body style={{ backgroundColor: "#192132", borderColor: "#232e45" }}>
            <h5 className="text-white mb-3">Agences en sous-effectif</h5>
            {hasSousEffectif ? (
              <ul className="list-group">
                {personnelData.sous_effectif.map((agence, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center" 
                      style={{ backgroundColor: "#232e45", color: "white", marginBottom: "8px", borderRadius: "5px" }}>
                    {agence.nom_agence} ({agence.ville})
                    <Badge bg="danger">
                      {agence.manque} {agence.manque > 1 ? 'postes vacants' : 'poste vacant'}
                    </Badge>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-white">Aucune agence en sous-effectif</p>
            )}
          </Card>
        </Col>
        <Col md={6}>
          <Card body style={{ backgroundColor: "#192132", borderColor: "#232e45", height: "100%" }}>
            {hasDistribution ? (
              <div style={{ height: "300px", width: "100%", position: "relative" }}>
                <Pie data={distributionData} options={distributionOptions} />
              </div>
            ) : (
              <div className="text-center text-white py-5">
                <p>Aucune donnée de distribution disponible</p>
              </div>
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card body style={{ backgroundColor: "#192132", borderColor: "#232e45" }}>
            {hasChauffeurs ? (
              <Bar data={chauffeursData} options={chauffeursOptions} />
            ) : (
              <div className="text-center text-white py-5">
                <p>Aucune donnée sur les chauffeurs disponible</p>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RessourcesHumainesChart;
