import React, { useState, useEffect } from 'react';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { fetchDataOld } from "../../../../services/fetchData";
import Reload from '../../../utils/reload';
import { Card, Form, Row, Col, Table, Badge, ButtonGroup, Button, InputGroup, Accordion } from 'react-bootstrap';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterListIcon from '@mui/icons-material/FilterList';
import GroupIcon from '@mui/icons-material/Group';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Enregistrer les composants nécessaires pour Chart.js
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, ArcElement);

const ComparaisonSousAgencesChart = () => {
  const [comparaisonData, setComparaisonData] = useState(null);
  const [selectedAgence, setSelectedAgence] = useState('');
  const [agences, setAgences] = useState([]);
  const [periodeType, setPeriodeType] = useState('mensuelle'); // mensuelle, hebdomadaire ou annuelle
  const [viewMode, setViewMode] = useState('chart'); // chart ou table
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [revenuTotal, setRevenuTotal] = useState(0);
  const [groupBy, setGroupBy] = useState('ville'); // 'ville' ou 'nom'
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  // Chargement initial des agences
  useEffect(() => {
    setLoading(true);
    fetchDataOld("all_agences", (data) => {
      if (data && Array.isArray(data)) {
        setAgences(data);
      }
      setLoading(false);
    }, (err) => {
      setError(err);
      setLoading(false);
    });
  }, []);

  // Chargement des données quand une agence est sélectionnée ou quand le type de période change
  useEffect(() => {
    if (selectedAgence) {
      setLoading(true);
      let endpointName;
      
      if (periodeType === 'annuelle') {
        // Pour la vue annuelle, nous utilisons les données mensuelles
        endpointName = 'sous_agences_performance_mensuelle';
      } else {
        endpointName = periodeType === 'hebdomadaire' 
          ? 'sous_agences_performance_hebdomadaire' 
          : 'sous_agences_performance_mensuelle';
      }
      
      // CORRECTION: Utiliser l'API correctement en séparant les paramètres
      fetch(`${process.env.REACT_APP_API_URL}/index.php?endpoint=${endpointName}&idAgence=${selectedAgence}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Ajout de l'en-tête d'autorisation si nécessaire
          'Authorization': `Bearer ${document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*\=\s*([^;]*).*$)|^.*$/, "$1")}`
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Données chargées:', data);
        
        // Calculer le revenu total pour toutes les sous-agences
        let total = 0;
        if (data && data.performance) {
          data.performance.forEach(item => {
            total += Number(item.montant);
          });
        }
        setRevenuTotal(total);
        
        setComparaisonData(data);
        setLoading(false);
      })
      .catch(err => {
        console.error('Erreur de chargement:', err);
        setError(err);
        setLoading(false);
      });
    }
  }, [selectedAgence, periodeType]);

  // Ajout d'un effet pour déboguer le chargement des données
  useEffect(() => {
    if (comparaisonData) {
      console.log(`Données chargées pour le type ${periodeType}:`, comparaisonData);
      
      // Vérification des données hebdomadaires
      if (periodeType === 'hebdomadaire' && (!comparaisonData.performance || comparaisonData.performance.length === 0)) {
        console.warn('Aucune donnée de performance hebdomadaire disponible');
      }
    }
  }, [comparaisonData, periodeType]);

  if (loading && !agences.length) return <Reload />;
  if (error) return <p className="text-white">Erreur lors du chargement des données: {error.message}</p>;

  const handleAgenceChange = (e) => {
    setSelectedAgence(e.target.value);
  };

  const handlePeriodeChange = (e) => {
    setPeriodeType(e.target.value);
  };

  const handleViewModeChange = (e) => {
    setViewMode(e.target.value);
  };

  const handleGroupByChange = (newGroupBy) => {
    setGroupBy(newGroupBy);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Fonction pour formater les montants en FCFA
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('fr-FR', { 
      style: 'currency', 
      currency: 'XAF',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  // Fonction utilitaire pour générer des couleurs cohérentes pour les sous-agences
  const generateColors = (index, alpha = 0.2) => {
    // Palette de couleurs prédéfinies diversifiées pour garantir un bon contraste
    const colorPalette = [
      { bg: `rgba(255, 99, 132, ${alpha})`, border: 'rgba(255, 99, 132, 1)' },    // Rose
      { bg: `rgba(54, 162, 235, ${alpha})`, border: 'rgba(54, 162, 235, 1)' },    // Bleu
      { bg: `rgba(255, 206, 86, ${alpha})`, border: 'rgba(255, 206, 86, 1)' },    // Jaune
      { bg: `rgba(75, 192, 192, ${alpha})`, border: 'rgba(75, 192, 192, 1)' },    // Turquoise
      { bg: `rgba(153, 102, 255, ${alpha})`, border: 'rgba(153, 102, 255, 1)' },  // Violet
      { bg: `rgba(255, 159, 64, ${alpha})`, border: 'rgba(255, 159, 64, 1)' },    // Orange
      { bg: `rgba(29, 209, 161, ${alpha})`, border: 'rgba(29, 209, 161, 1)' },    // Vert
      { bg: `rgba(238, 90, 36, ${alpha})`, border: 'rgba(238, 90, 36, 1)' },      // Rouge-orange
      { bg: `rgba(106, 137, 204, ${alpha})`, border: 'rgba(106, 137, 204, 1)' },  // Bleu-violet
      { bg: `rgba(240, 147, 43, ${alpha})`, border: 'rgba(240, 147, 43, 1)' },    // Ambre
      { bg: `rgba(46, 204, 113, ${alpha})`, border: 'rgba(46, 204, 113, 1)' },    // Émeraude
      { bg: `rgba(231, 76, 60, ${alpha})`, border: 'rgba(231, 76, 60, 1)' },      // Rouge pompier
      { bg: `rgba(52, 152, 219, ${alpha})`, border: 'rgba(52, 152, 219, 1)' },    // Bleu peter
      { bg: `rgba(155, 89, 182, ${alpha})`, border: 'rgba(155, 89, 182, 1)' },    // Améthyste
      { bg: `rgba(26, 188, 156, ${alpha})`, border: 'rgba(26, 188, 156, 1)' },    // Turquoise
      { bg: `rgba(241, 196, 15, ${alpha})`, border: 'rgba(241, 196, 15, 1)' },    // Soleil
    ];
    
    // Utiliser l'index pour sélectionner une couleur dans la palette
    return colorPalette[index % colorPalette.length];
  };

  // Fonction pour générer un identifiant cohérent pour une sous-agence (pour permettre des couleurs cohérentes)
  const getSousAgenceColorIndex = (sousAgenceId, sousAgenceNom) => {
    // On utilise l'ID comme base, mais on ajoute le nom pour plus de diversité
    const baseString = `${sousAgenceId}-${sousAgenceNom}`;
    let hash = 0;
    for (let i = 0; i < baseString.length; i++) {
      hash = baseString.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Convertir en nombre positif
    return Math.abs(hash);
  };

  // Fonction pour convertir les noms de mois en français
  const getMoisEnFrancais = (moisAnglais) => {
    const moisMap = {
      'January': 'Janvier',
      'February': 'Février',
      'March': 'Mars',
      'April': 'Avril',
      'May': 'Mai',
      'June': 'Juin',
      'July': 'Juillet',
      'August': 'Août',
      'September': 'Septembre',
      'October': 'Octobre',
      'November': 'Novembre',
      'December': 'Décembre'
    };
    return moisMap[moisAnglais] || moisAnglais;
  };

  // Préparation des données pour les graphiques
  const preparePerformanceChart = () => {
    if (!comparaisonData || !comparaisonData.sous_agences || !comparaisonData.performance) {
      return null;
    }

    let periodesSet = new Set();
    let periodes = [];
    let labels = [];
    let datasets = [];

    if (periodeType === 'annuelle') {
      // Agrégation des données par année et par sous-agence
      const yearlyData = {};
      comparaisonData.sous_agences.forEach(sa => {
        yearlyData[sa.idSousAgence] = 0;
      });

      comparaisonData.performance.forEach(perf => {
        if (yearlyData[perf.idSousAgence] !== undefined) {
          yearlyData[perf.idSousAgence] += Number(perf.montant);
        }
      });

      // Préparation des données pour le graphique en camembert avec des couleurs distinctes
      const sousAgenceLabels = comparaisonData.sous_agences.map(sa => sa.nom);
      const sousAgenceData = comparaisonData.sous_agences.map(sa => yearlyData[sa.idSousAgence] || 0);
      
      // Utilisation de couleurs distinctes pour chaque sous-agence
      const sousAgenceColors = comparaisonData.sous_agences.map((sa) => {
        // Générer un index de couleur basé sur l'ID et le nom de la sous-agence
        const colorIndex = getSousAgenceColorIndex(sa.idSousAgence, sa.nom);
        const color = generateColors(colorIndex, 0.7); // Alpha plus élevé pour le camembert
        
        // Utiliser la couleur de background générée mais garder la bordure de l'agence principale
        return sa.backgroundColor ? 
          `rgba(${parseInt(Math.random() * 255)}, ${parseInt(Math.random() * 255)}, ${parseInt(Math.random() * 255)}, 0.7)` : 
          color.bg;
      });
      
      const sousAgenceBorders = comparaisonData.sous_agences.map((sa) => {
        // Garder la couleur de bordure de l'agence si disponible
        return sa.borderColor || generateColors(getSousAgenceColorIndex(sa.idSousAgence, sa.nom)).border;
      });

      return {
        type: 'doughnut',
        data: {
          labels: sousAgenceLabels,
          datasets: [{
            data: sousAgenceData,
            backgroundColor: sousAgenceColors,
            borderColor: sousAgenceBorders,
            borderWidth: 1,
            hoverOffset: 10, // Augmenter le décalage au survol pour meilleure visibilité
            hoverBorderWidth: 2, // Épaissir la bordure au survol
            hoverBackgroundColor: sousAgenceColors.map(color => {
              // Rendre la couleur légèrement plus vive au survol
              return color.replace(/rgba\((\d+),\s*(\d+),\s*(\d+),\s*[\d.]+\)/, 
                (m, r, g, b) => `rgba(${r}, ${g}, ${b}, 0.85)`);
            })
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'right',
              labels: { 
                color: '#FFFFFF',
                padding: 15,
                usePointStyle: true,
                pointStyle: 'circle',
                font: {
                  size: 12,
                  weight: 'bold'
                }
              }
            },
            title: {
              display: true,
              text: 'Répartition des revenus annuels par sous-agence',
              color: '#FFFFFF',
              font: { 
                size: 16,
                weight: 'bold'
              },
              padding: {
                bottom: 30
              }
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  const label = context.label || '';
                  const value = context.raw;
                  const total = context.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
                  const percentage = total > 0 ? Math.round((value / total) * 100) : 0;
                  return `${label}: ${formatCurrency(value)} (${percentage}%)`;
                }
              },
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              titleFont: {
                weight: 'bold'
              },
              bodyFont: {
                size: 14
              },
              padding: 12,
              cornerRadius: 6
            }
          },
          layout: {
            padding: 20
          },
          animation: {
            animateRotate: true,
            animateScale: true
          }
        }
      };
    } else {
      // Pour les vues mensuelles et hebdomadaires
      comparaisonData.performance.forEach(p => periodesSet.add(p.periode));
      periodes = Array.from(periodesSet).sort((a, b) => a - b);

      labels = periodes.map(p => 
        periodeType === 'hebdomadaire' ? `Semaine ${p}` : 
        getMoisEnFrancais(comparaisonData.performance.find(perf => perf.periode === p)?.nom_periode || `Mois ${p}`)
      );

      datasets = comparaisonData.sous_agences.map((sa) => {
        // Générer un index de couleur cohérent pour cette sous-agence
        const colorIndex = getSousAgenceColorIndex(sa.idSousAgence, sa.nom);
        const color = generateColors(colorIndex);
        
        const dataPoints = periodes.map(periode => {
          const perfEntry = comparaisonData.performance.find(
            p => p.idSousAgence === sa.idSousAgence && p.periode === periode
          );
          return perfEntry ? perfEntry.montant : 0;
        });

        return {
          label: sa.nom,
          data: dataPoints,
          // Utiliser une couleur de fond aléatoire pour chaque sous-agence
          backgroundColor: `rgba(${parseInt(Math.random() * 255)}, ${parseInt(Math.random() * 255)}, ${parseInt(Math.random() * 255)}, 0.2)`,
          // Conserver la couleur de bordure de l'agence principale si disponible
          borderColor: sa.borderColor || color.border,
          borderWidth: 1.5,
          tension: periodeType === 'hebdomadaire' ? 0.4 : 0,
          fill: periodeType === 'hebdomadaire',
          pointBackgroundColor: sa.borderColor || color.border,
          pointBorderColor: '#fff',
          pointHoverRadius: 6,
          pointRadius: 4
        };
      });

      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            labels: { color: '#FFFFFF' }
          },
          title: {
            display: true,
            text: `Évolution des revenus ${periodeType === 'hebdomadaire' ? 'hebdomadaires' : 'mensuels'} par sous-agence`,
            color: '#FFFFFF',
            font: { size: 16 }
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (label) label += ': ';
                if (context.parsed.y !== null) {
                  label += formatCurrency(context.parsed.y);
                }
                return label;
              }
            }
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: '#FFFFFF',
              callback: function(value) {
                return formatCurrency(value);
              }
            },
            grid: { color: 'rgba(255, 255, 255, 0.1)' }
          },
          x: {
            ticks: { color: '#FFFFFF' },
            grid: { color: 'rgba(255, 255, 255, 0.1)' }
          }
        }
      };

      return {
        type: periodeType === 'hebdomadaire' ? 'line' : 'bar',
        data: { labels, datasets },
        options
      };
    }
  };

  // Préparation des données pour le tableau
  const prepareTableData = () => {
    if (!comparaisonData || !comparaisonData.sous_agences || !comparaisonData.performance) {
      return null;
    }

    if (periodeType === 'annuelle') {
      // Agrégation des données par année et par sous-agence
      const yearlyData = {};
      comparaisonData.sous_agences.forEach(sa => {
        yearlyData[sa.idSousAgence] = 0;
      });

      comparaisonData.performance.forEach(perf => {
        if (yearlyData[perf.idSousAgence] !== undefined) {
          yearlyData[perf.idSousAgence] += Number(perf.montant);
        }
      });

      return comparaisonData.sous_agences.map(sa => ({
        id: sa.idSousAgence,
        nom: sa.nom,
        ville: sa.ville,
        revenu: yearlyData[sa.idSousAgence] || 0,
        pourcentage: revenuTotal > 0 ? ((yearlyData[sa.idSousAgence] || 0) / revenuTotal * 100).toFixed(2) : 0
      }));
    } else {
      // Pour les vues mensuelles et hebdomadaires, regrouper par période puis par sous-agence
      let periodesSet = new Set();
      comparaisonData.performance.forEach(p => periodesSet.add(p.periode));
      const periodes = Array.from(periodesSet).sort((a, b) => a - b);

      let rowId = 0;
      return periodes.flatMap(periode => {
        const periodeName = periodeType === 'hebdomadaire' 
          ? `Semaine ${periode}` 
          : getMoisEnFrancais(comparaisonData.performance.find(p => p.periode === periode)?.nom_periode || `Mois ${periode}`);
        
        const rowData = comparaisonData.sous_agences.map(sa => {
          const perfEntry = comparaisonData.performance.find(
            p => p.idSousAgence === sa.idSousAgence && p.periode === periode
          );
          return {
            id: ++rowId,
            periode: periodeName,
            nom: sa.nom,
            ville: sa.ville,
            revenu: perfEntry ? perfEntry.montant : 0
          };
        });
        
        return rowData;
      });
    }
  };

  // Exporter les données
  const exportToExcel = () => {
    const tableData = prepareTableData();
    if (!tableData) return;

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    
    XLSX.utils.book_append_sheet(workbook, worksheet, "Performance");
    
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `performance_${periodeType}_sous_agences.xlsx`;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const exportToPDF = () => {
    const tableData = prepareTableData();
    if (!tableData) return;

    const doc = new jsPDF();
    doc.text(`Performance des sous-agences - ${periodeType.charAt(0).toUpperCase() + periodeType.slice(1)}`, 14, 15);
    
    const columnsForPDF = periodeType === 'annuelle' 
      ? [['Sous-agence', 'Ville', 'Revenu', 'Pourcentage']]
      : [['Période', 'Sous-agence', 'Ville', 'Revenu']];
    
    const dataForPDF = tableData.map(item => {
      if (periodeType === 'annuelle') {
        return [
          item.nom,
          item.ville,
          formatCurrency(item.revenu),
          `${item.pourcentage}%`
        ];
      } else {
        return [
          item.periode,
          item.nom,
          item.ville,
          formatCurrency(item.revenu)
        ];
      }
    });
    
    doc.autoTable({
      head: columnsForPDF,
      body: dataForPDF,
      startY: 20,
      theme: 'grid',
      styles: { fontSize: 8, cellPadding: 1 },
      headStyles: { fillColor: [41, 128, 185], textColor: 255 }
    });
    
    doc.save(`performance_${periodeType}_sous_agences.pdf`);
  };

  const tableData = prepareTableData();
  const chartConfig = preparePerformanceChart();

  // Grouper les données par ville ou par nom de sous-agence
  const groupData = (data) => {
    if (!data) return [];
    
    const grouped = data.reduce((acc, item) => {
      const groupKey = item[groupBy];
      if (!acc[groupKey]) {
        acc[groupKey] = {
          items: [],
          totalRevenu: 0
        };
      }
      acc[groupKey].items.push(item);
      acc[groupKey].totalRevenu += Number(item.revenu);
      return acc;
    }, {});
    
    return Object.keys(grouped).map(key => ({
      groupKey: key,
      items: grouped[key].items,
      totalRevenu: grouped[key].totalRevenu
    }));
  };
  
  // Filtrer les données
  const filterData = (data) => {
    if (!searchTerm || !data) return data;
    
    return data.filter(item => 
      item.nom?.toLowerCase().includes(searchTerm.toLowerCase()) || 
      item.ville?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item.periode && item.periode.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  };
  
  // Trier les données
  const sortData = (data) => {
    if (!sortConfig.key) return data;
    
    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };
  
  const filteredData = filterData(tableData);
  const sortedData = sortData(filteredData);
  const groupedData = groupData(sortedData);

  return (
    <div>
      <Card body style={{ backgroundColor: "#192132", borderColor: "#232e45", marginBottom: "20px" }}>
        <Form>
          <Row className="mb-4">
            <Col md={4}>
              <Form.Group>
                <Form.Label className="text-white">Sélectionner une agence</Form.Label>
                <Form.Select value={selectedAgence} onChange={handleAgenceChange}>
                  <option value="">Choisir une agence</option>
                  {agences.map(agence => (
                    <option key={agence.idAgence} value={agence.idAgence}>
                      {agence.nom} - {agence.ville}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            
            <Col md={4}>
              <Form.Group>
                <Form.Label className="text-white">Type de période</Form.Label>
                <Form.Select value={periodeType} onChange={handlePeriodeChange}>
                  <option value="mensuelle">Mensuelle</option>
                  <option value="hebdomadaire">Hebdomadaire</option>
                  <option value="annuelle">Annuelle</option>
                </Form.Select>
              </Form.Group>
            </Col>
            
            <Col md={4}>
              <Form.Group>
                <Form.Label className="text-white">Mode d'affichage</Form.Label>
                <Form.Select value={viewMode} onChange={handleViewModeChange}>
                  <option value="chart">Graphique</option>
                  <option value="table">Tableau</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        
        {!selectedAgence && (
          <div className="text-center text-white p-5">
            <h5>Veuillez sélectionner une agence pour voir les statistiques de ses sous-agences</h5>
          </div>
        )}
        
        {selectedAgence && loading && <Reload />}
        
        {selectedAgence && !loading && (!comparaisonData || !comparaisonData.sous_agences || comparaisonData.sous_agences.length === 0) && (
          <div className="text-center text-white p-5">
            <h5>Aucune donnée disponible pour cette agence</h5>
            <p>Cette agence n'a pas de sous-agences ou aucune donnée n'est disponible.</p>
          </div>
        )}
        
        {selectedAgence && !loading && comparaisonData && comparaisonData.sous_agences && comparaisonData.sous_agences.length > 0 && (
          <>
            {revenuTotal > 0 && (
              <div className="text-center text-white mb-3">
                <h5>Revenu total: {formatCurrency(revenuTotal)}</h5>
              </div>
            )}
            
            {viewMode === 'chart' && chartConfig && (
              <div className="mb-4">
                {chartConfig.type === 'doughnut' ? (
                  <div style={{ maxHeight: '500px' }}>
                    <Doughnut data={chartConfig.data} options={chartConfig.options} />
                  </div>
                ) : chartConfig.type === 'line' ? (
                  <Line data={chartConfig.data} options={chartConfig.options} />
                ) : (
                  <Bar data={chartConfig.data} options={chartConfig.options} />
                )}
              </div>
            )}
            
            {viewMode === 'table' && tableData && (
              <div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <InputGroup style={{ maxWidth: '300px' }}>
                    <InputGroup.Text><FilterListIcon fontSize="small" /></InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Rechercher..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </InputGroup>
                  
                  <div>
                    <ButtonGroup className="me-2">
                      <Button 
                        variant={groupBy === 'ville' ? 'primary' : 'outline-primary'} 
                        onClick={() => handleGroupByChange('ville')}
                      >
                        <LocationCityIcon fontSize="small" className="me-1" /> Grouper par ville
                      </Button>
                      <Button 
                        variant={groupBy === 'nom' ? 'primary' : 'outline-primary'} 
                        onClick={() => handleGroupByChange('nom')}
                      >
                        <GroupIcon fontSize="small" className="me-1" /> Grouper par sous-agence
                      </Button>
                    </ButtonGroup>
                    
                    <ButtonGroup>
                      <Button variant="success" onClick={exportToExcel} className="me-1">
                        <FileDownloadIcon fontSize="small" className="me-1" /> Excel
                      </Button>
                      <Button variant="danger" onClick={exportToPDF}>
                        <FileDownloadIcon fontSize="small" className="me-1" /> PDF
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
                
                <div className="table-responsive" style={{ maxHeight: '600px' }}>
                  {periodeType === 'annuelle' ? (
                    <Accordion defaultActiveKey="0" className="mb-3">
                      {groupedData.map((group, index) => (
                        <Accordion.Item key={index} eventKey={index.toString()} className="bg-dark text-white">
                          <Accordion.Header className="d-flex align-items-center" style={{ background: '#343a40' }}>
                            <div className="d-flex justify-content-between w-100 pe-5 align-items-center">
                              <span>
                                <Badge bg="primary" className="me-2">
                                  {group.items.length}
                                </Badge>
                                {group.groupKey}
                              </span>
                              <Badge bg="success" pill>
                                {formatCurrency(group.totalRevenu)}
                              </Badge>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="p-0">
                            <Table striped bordered hover variant="dark" className="mb-0">
                              <thead>
                                <tr>
                                  <th style={{ cursor: 'pointer' }} onClick={() => handleSort('nom')}>
                                    Sous-agence {sortConfig.key === 'nom' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                                  </th>
                                  <th style={{ cursor: 'pointer' }} onClick={() => handleSort('ville')}>
                                    Ville {sortConfig.key === 'ville' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                                  </th>
                                  <th style={{ cursor: 'pointer' }} onClick={() => handleSort('revenu')}>
                                    Revenu {sortConfig.key === 'revenu' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                                  </th>
                                  <th style={{ cursor: 'pointer' }} onClick={() => handleSort('pourcentage')}>
                                    Pourcentage {sortConfig.key === 'pourcentage' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {group.items.map((item, itemIndex) => {
                                  // Obtenir la couleur pour cette sous-agence
                                  const sousAgence = comparaisonData.sous_agences.find(sa => sa.nom === item.nom);
                                  const colorIndex = sousAgence ? getSousAgenceColorIndex(sousAgence.idSousAgence, sousAgence.nom) : itemIndex;
                                  const color = generateColors(colorIndex);
                                  
                                  return (
                                    <tr key={itemIndex} style={{
                                      borderLeft: `4px solid ${sousAgence?.borderColor || color.border}`
                                    }}>
                                      <td>
                                        <span className="d-inline-block mr-2" style={{
                                          width: '12px', 
                                          height: '12px', 
                                          backgroundColor: sousAgence?.borderColor || color.border,
                                          borderRadius: '50%',
                                          marginRight: '8px'
                                        }}></span>
                                        {item.nom}
                                      </td>
                                      <td>{item.ville}</td>
                                      <td>{formatCurrency(item.revenu)}</td>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div className="progress flex-grow-1 me-2" style={{ height: '8px', backgroundColor: '#343a40' }}>
                                            <div 
                                              className="progress-bar" 
                                              role="progressbar" 
                                              style={{ 
                                                width: `${item.pourcentage}%`, 
                                                backgroundColor: sousAgence?.borderColor || color.border 
                                              }} 
                                              aria-valuenow={item.pourcentage} 
                                              aria-valuemin="0" 
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                          <span>{item.pourcentage}%</span>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  ) : (
                    <Accordion defaultActiveKey="0" className="mb-3">
                      {groupedData.map((group, index) => (
                        <Accordion.Item key={index} eventKey={index.toString()} className="bg-dark text-white">
                          <Accordion.Header className="d-flex align-items-center" style={{ background: '#343a40' }}>
                            <div className="d-flex justify-content-between w-100 pe-5 align-items-center">
                              <span>
                                <Badge bg="primary" className="me-2">
                                  {group.items.length}
                                </Badge>
                                {group.groupKey}
                              </span>
                              <Badge bg="success" pill>
                                {formatCurrency(group.totalRevenu)}
                              </Badge>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="p-0">
                            <Table striped bordered hover variant="dark" className="mb-0">
                              <thead>
                                <tr>
                                  <th style={{ cursor: 'pointer' }} onClick={() => handleSort('periode')}>
                                    Période {sortConfig.key === 'periode' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                                  </th>
                                  <th style={{ cursor: 'pointer' }} onClick={() => handleSort('nom')}>
                                    Sous-agence {sortConfig.key === 'nom' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                                  </th>
                                  <th style={{ cursor: 'pointer' }} onClick={() => handleSort('ville')}>
                                    Ville {sortConfig.key === 'ville' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                                  </th>
                                  <th style={{ cursor: 'pointer' }} onClick={() => handleSort('revenu')}>
                                    Revenu {sortConfig.key === 'revenu' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {group.items.map((item, itemIndex) => {
                                  // Obtenir la couleur pour cette sous-agence
                                  const sousAgence = comparaisonData.sous_agences.find(sa => sa.nom === item.nom);
                                  const colorIndex = sousAgence ? getSousAgenceColorIndex(sousAgence.idSousAgence, sousAgence.nom) : itemIndex;
                                  const color = generateColors(colorIndex);
                                  
                                  return (
                                    <tr key={itemIndex} style={{
                                      borderLeft: `4px solid ${sousAgence?.borderColor || color.border}`
                                    }}>
                                      <td>{item.periode}</td>
                                      <td>
                                        <span className="d-inline-block mr-2" style={{
                                          width: '12px', 
                                          height: '12px', 
                                          backgroundColor: sousAgence?.borderColor || color.border,
                                          borderRadius: '50%',
                                          marginRight: '8px'
                                        }}></span>
                                        {item.nom}
                                      </td>
                                      <td>{item.ville}</td>
                                      <td>{formatCurrency(item.revenu)}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export default ComparaisonSousAgencesChart;
