import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../../../assets/css/style_defaultWidget.css";
import "../../../pages/global/style_global.scss";

import SearchIcon from "@mui/icons-material/Search";
import { Form, InputGroup } from "react-bootstrap";
import TableDesny from "../../customComponents/tableDesny";
import NotificationManager from "../../utils/notificationManger";
import { addColis } from "../../../services/fetchData";

const TableColis = () => {
    const [newRowData, setNewRowData] = useState([]);
    const [notificationQueue, setNotificationQueue] = useState([]);
    const [dataSend, setDataSend] = useState([]);
    const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
    const gridStyle = useMemo(
      () => ({ height: "100%", width: "100%", background: "red !important" }),
      []
    );
    const handleDataSend = async (updatedData) => {
      setDataSend((prevData) => [...prevData, updatedData]);
    }
    const [searchValue, setSearchValue] = useState(""); // État pour stocker la valeur de recherche
    // Gestionnaire d'événements pour la modification de la valeur de recherche
    const handleSearchChange = useCallback((event) => {
      setSearchValue(event.target.value);
    }, []);

    const showSuccessNotification = () => {
      setNotificationQueue([
        ...notificationQueue,
        { isSuccess: true, isError: false, isAlert: false },
      ]);
    };
  
    const showErrorNotification = useCallback(() => {
      setNotificationQueue([
        ...notificationQueue,
        { isSuccess: false, isError: true, isAlert: false },
      ]);
    }, [notificationQueue]);
  
    const handelSubmit = useCallback (async (formData) => {
      if (!formData) return null;
      try {
        const response = await addColis(formData);
        if (response && response.success) {
          showSuccessNotification();
        } else {
          showErrorNotification();
        }
      } catch (error) {
        showErrorNotification();
      }
    },[]);
    useEffect(() => {
      handelSubmit(dataSend[dataSend.length -1])
    }, [handelSubmit, dataSend]);

  let dataTable = [
    {field: "idColis",hide: true,editable: false},
    {field: "numero",headerName: "Numero",editable: false},  
    {field: "voyageur" ,headerName: "Expéditeur",editable: false},
    { field: "destinataire", editable: true },
    { field: "destination", editable: false},
    {field: "dateDepot", cellEditor: 'agDateStringCellEditor',editable: true, 
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString();
      },
    },

    {
      field: "statut",
      headerName: "Statut",
      cellEditor: "agSelectCellEditor",
      editable: true,
      cellEditorParams: {
        values: ["En cours de livraison", "Livré", "Perdu", "Nouveau", "Retourné"],
        cellEditorPopup: true,
      },
        cellStyle: function (params) {
          if (params.value === "En cours de livraison") {
            return { backgroundColor: "#d6bd3d", color: "black", fontWeight: "500"};
          }
          if (params.value === "Livré") {
            return { backgroundColor: "#10b981", color: "black", fontWeight: "500"};
          }
          if (params.value === "Perdu") {
            return { backgroundColor: "#dc3545", color: "black" };
          }
          if (params.value === "Nouveau") {
            return { backgroundColor: "#0d6efd", color: "black" };
          }
          if (params.value === "Retourné") {
            return { backgroundColor: "#6c757d", color: "black" };
          }
          return null;
        },
      },
      { field: "dateRetrait",  cellEditor: 'agDateStringCellEditor', editable: true, 
        valueFormatter: (params) => {
          return new Date(params.value).toLocaleDateString();
        },
      cellStyle: function (params) {
        if (params.value !== "") {
          return { color: "#10b981", fontWeight: "500"};
        }
        return null;
      }},
          { field: "montant", editable: true },
    {
      field: "nomAgence",
      headerName: "Agence",
      cellEditor: "agSelectCellEditor",
      editable: true,
      cellEditorParams: {
        values: ["Agence Brazzaville", "Agence Pointe-Noire", "Agence Dolisie"],
        cellEditorPopup: true,
      },
      cellStyle: function (params) {
        if (params.value === "Agence Brazzaville") {
          return { color: "#10b981" };
        }
        if (params.value === "Agence Pointe-Noire") {
          return { color: "#7e91ff" };
        }
        if (params.value === "Agence Dolisie") {
          return { color: "#dc3545" };
        }
        return null;
      },
    },
    { field: "cle", hide: true },
  ];

    return (
       <div style={containerStyle} >
      <div className=" text-end pb-2">
        <InputGroup
          className="ms-auto  custom-placeholder"
          style={{ width: "37%" }}
        >
          <Form.Control
            type="text"
            placeholder="Rechercher ..."
            onChange={handleSearchChange}
            className="border-none rounded-start "
            style={{ background: "", border: "solid 1px white" }}
          />

          <InputGroup.Text
            id="basic-addon2"
            style={{ background: "none", borderLeft: "none !important" }}
            className="rounded-end"
          >
            <SearchIcon className="text-light" />
          </InputGroup.Text>
          
        </InputGroup>
      </div>
      <TableDesny
         gridStyle={gridStyle}
         dataTable={dataTable}
         searchKeys={[
           "nomVoyageur",
           "prenomVoyageur",
           "numero",
           "dateReservation",
           "dateDepot",
           "dateRetrait",
           "destination",
           "montant",
         ]}
         endPoint="allColis"
         itemMontant="montant"
         searchValue={searchValue}
         idItemClick="idColis"
         page="update_employe"
         onRowDataChange={(newRowData) => {
           setNewRowData(newRowData);
         }}
         dataSend={handleDataSend}
         clickable={false}
      />
            <NotificationManager notificationQueue={notificationQueue} />
    </div>
    );
};

export default TableColis;