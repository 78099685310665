import React, {useState } from "react";
import TableColis from "../../components/widgets/actives/tableColis";
import WidgetColis from "../../components/widgets/actives/widgetColis";
import Reload from "../../components/utils/reload";
import { ErrorConnexion } from "../../components/modal/error500";
import { useCustomQuery } from "../../components/utils/useCustomQuery ";

const Colis = () => {
  const [error, setError] = useState(null);

  const {data : AllColisMois, isLoading} = useCustomQuery(["nb_colis_par_mois_agence"], "nb_colis_par_mois_agence", setError);

  if (isLoading) return <Reload />;
  if (error) return <ErrorConnexion error={error} />;

  return (
    <div className="m-3 mt-5 pt-3">
      <p className="fw-semibold">
        <span className="text-secondary">Liste Colis</span>{" "}
        <span className="text-light">&#62;</span>{" "}
        <span className="text-light">Tous les Colis</span>
      </p>
      <WidgetColis AllColisMois={AllColisMois}/>
      <p className="text-secondary fw-semibold mb-0">Tableau des colis</p>
      <div className="" style={{ height: "70vh" }}>
        <TableColis/>
      </div>
    </div>
  );
};

export default Colis;
