import React from 'react';
import { Card } from 'react-bootstrap';
import '../../pages/global/style_global.scss';

/**
 * CardWidget - Composant réutilisable pour créer des cartes stylisées
 * 
 * @param {Object} props - Les propriétés du composant
 * @param {React.ReactNode} props.children - Le contenu à afficher dans la carte
 * @param {string} props.titre - Titre de la carte
 * @param {string} props.sousTitre - Sous-titre optionnel de la carte
 * @param {React.ReactNode} props.toolbar - Barre d'outils optionnelle à afficher en haut de la carte
 * @param {string} props.className - Classes CSS supplémentaires
 * @param {boolean} props.fullHeight - Indique si la carte doit occuper toute la hauteur disponible
 * @param {Object} props.style - Styles CSS supplémentaires
 * @returns {React.ReactElement} Carte stylisée avec contenu
 */

const CardWidget = ({
  children,
  titre,
  sousTitre,
  toolbar,
  className = '',
  fullHeight = true,
  style = {}
}) => {
  return (
    <Card 
      className={`shadow-sm mb-4 p-0 ${className} ${fullHeight ? 'h-100' : ''}`}
      style={{ 
        backgroundColor: "#192132", 
        border: "1px solid #192132",
        ...style 
      }}
    >
      {(titre || toolbar) && (
        <Card.Header 
          className="d-flex justify-content-between align-items-center py-3"
          style={{ 
            backgroundColor: "#192132", 
            borderBottom: "1px solid #505b9a" 
          }}
        >
          <div>
            {titre && <h5 className="mb-0 text-white">{titre}</h5>}
            {sousTitre && <small className="text-muted">{sousTitre}</small>}
          </div>
          {toolbar && <div>{toolbar}</div>}
        </Card.Header>
      )}
      <Card.Body 
        className="p-3"
        style={{ color: "#ffffff" }}
      >
        {children}
      </Card.Body>
    </Card>
  );
};

export default CardWidget;
