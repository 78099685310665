import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Card, Dropdown, Form, Row, Col } from "react-bootstrap";
import { getElement, deleteElement, addAgence } from "../../services/fetchData";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationManager from "../../components/utils/notificationManger";
import Reload from "../../components/utils/reload";
import { useDeleteMutation, usePostMutation } from "../../components/utils/useCustomQuery ";
import ConfirmYesNo from "../../components/modal/confirmYesNo";

const UpdateAgence = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isNew = id === "new";
  const [agenceData, setAgenceData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [notificationQueue, setNotificationQueue] = useState([]);
  
  // Modal de confirmation de suppression
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentToDelete, setCurrentToDelete] = useState(null);
  
  const saveButtonRef = useRef();
  
  console.log("id", id);
  console.log("isNew", isNew);
  
  const initialFormData = {
    nom: "",
    ville: "",
    adresse: "",
    telephone: "",
    responsable: "",
    backgroundColor: "",
    borderColor: "",
    idAgence: id === "new" ? "" : id
  };
  
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (id !== "new") {
      getElement("view_agences", "idAgence", id, (data) => {
        setAgenceData(data);
        if (data) {
          setFormData({
            ...initialFormData,
            nom: data.nom || initialFormData.nom,
            ville: data.ville || initialFormData.ville,
            adresse: data.adresse || initialFormData.adresse,
            telephone: data.telephone || initialFormData.telephone,
            responsable: data.responsable || initialFormData.responsable,
            backgroundColor: data.backgroundColor || initialFormData.backgroundColor,
            borderColor: data.borderColor || initialFormData.borderColor,
            idAgence: id
          });
        }
      });
    } else {
      setIsEditMode(true);
    }
  }, []);

  const updateField = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setAgenceData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  
  const handleEditClick = () => {
    setIsEditMode(true);
  };
  
  const handleSaveClick = () => {
    setIsEditMode(false);
    showSuccessNotification();
  };
  
  const handleFieldChange = (event, fieldName) => {
    updateField(fieldName, event.target.value);
  };

  const addMutation = usePostMutation(["agences"], addAgence);
  const deleteMutation = useDeleteMutation(["agences"]);

  const handleOpenDeleteModal = (agenceData) => {
    setCurrentToDelete(agenceData);
    setShowConfirmModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    addMutation.mutate(formData, {
      onSuccess: () => {
        showSuccessNotification();
        setTimeout(() => {
          navigate("/agences");
        }, 700);
      },
      onError: () => {
        showErrorNotification();
      },
    });
  };

  const handleBtnDelete = () => {
    if (currentToDelete) {
      deleteMutation.mutate(
        {
          tableName: "agences",
          idFieldName: "idAgence",
          id: id,
          cle: currentToDelete.cle,
        },
        {
          onSuccess: () => {
            setShowConfirmModal(false);
            showSuccessNotification();
            setTimeout(() => {
              navigate("/agences");
            }, 700);
          },
          onError: () => {
            showErrorNotification();
          },
        }
      );
    }
  };

  const showSuccessNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: true, isError: false, isAlert: false },
    ]);
  };

  const showErrorNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: false, isError: true, isAlert: false },
    ]);
  };

  const simulateSaveButtonClick = () => {
    saveButtonRef.current.click();
  };

  if (!agenceData && id !== "new") {
    return <Reload />;
  }

  return (
    <div>
      <p className="fw-semibold m-3 mt-5 pt-3">
        <span className="text-secondary">Gestion des agences</span>{" "}
        <span className="text-secondary">&#62;</span>{" "}
        <NavLink
          to="/agences"
          className="text-secondary text-decoration-none hover-link-in"
        >
          Toutes les agences
        </NavLink>
        <span className="text-light"> &#62;</span>{" "}
        <span className="text-light">
          {agenceData ? agenceData.nom : "Nouvelle agence"}
        </span>
      </p>
      
      <div className="container-fluid my-2">
        <Form className="pt-3" onSubmit={handleSubmit}>
          <Card body>
            <div className="d-flex">
              <Card.Title className="pb-3 title">Détails de l'agence</Card.Title>
              {isEditMode ? (
                <Alert variant="warning" className="p-1 ms-auto fw-semibold">
                  Mode modification
                </Alert>
              ) : (
                <p></p>
              )}
              <Dropdown className="ms-auto">
                <Dropdown.Toggle id="dropdown-basic">
                  Outils <MenuIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {isEditMode ? (
                    <Dropdown.Item
                      style={{ fontSize: "0.9rem" }}
                      type="submit"
                      onClick={() => {
                        simulateSaveButtonClick();
                        handleSaveClick();
                      }}
                    >
                      Enregistrer
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      style={{ fontSize: "0.9rem" }}
                      onClick={handleEditClick}
                    >
                      Modifier
                    </Dropdown.Item>
                  )}
                  {!isNew && (
                    <Dropdown.Item
                      style={{ fontSize: "0.9rem" }}
                      onClick={() => handleOpenDeleteModal(agenceData)}
                    >
                      Supprimer
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            
            <p className="" style={{ fontSize: "0.8rem !important" }}>
              Le formulaire d'informations de l'agence.
            </p>
            <hr />
            
            <Card.Title className="pb-1 title">Informations générales</Card.Title>
            <p className="fw-semibold">
              Veuillez fournir les informations générales de l'agence.
            </p>
            
            <Form.Group as={Row} className="mt-4" controlId="adress">
              <Form.Label column sm="2" lg="2" md="2" className="mb-4">
                Nom de l'agence
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="text"
                  name="nom"
                  value={agenceData ? agenceData.nom : formData.nom}
                  onChange={(e) => handleFieldChange(e, "nom")}
                  disabled={!isEditMode}
                  required
                />
              </Col>
              <Col sm="5">
                <Form.Select
                  name="ville"
                  value={agenceData ? agenceData.ville : formData.ville}
                  onChange={(e) => handleFieldChange(e, "ville")}
                  disabled={!isEditMode}
                  required
                >
                  <option value="">Sélectionnez une ville</option>
                  <option value="Brazzaville">Brazzaville</option>
                  <option value="Pointe-Noire">Pointe-Noire</option>
                  <option value="Dolisie">Dolisie</option>
                </Form.Select>
              </Col>
            </Form.Group>
            
            <Form.Group as={Row} controlId="adress">
              <Form.Label column sm="2" lg="2" md="2">
                Adresse
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="text"
                  name="adresse"
                  value={agenceData ? agenceData.adresse : formData.adresse}
                  onChange={(e) => handleFieldChange(e, "adresse")}
                  disabled={!isEditMode}
                  required
                />
              </Col>
              <Col sm="5">
                <Form.Group as={Row} className="mb-4" controlId="adress">
                  <Form.Label column sm="2" lg="2" md="2" className="mb-4">
                    Téléphone
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      type="text"
                      name="telephone"
                      value={agenceData ? agenceData.telephone : formData.telephone}
                      onChange={(e) => handleFieldChange(e, "telephone")}
                      disabled={!isEditMode}
                      required
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Form.Group>
            
            <Form.Group as={Row} controlId="adress">
              <Form.Label column sm="2" lg="2" md="2" className="mb-4">
                Responsable
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="text"
                  name="responsable"
                  value={agenceData ? agenceData.responsable : formData.responsable}
                  onChange={(e) => handleFieldChange(e, "responsable")}
                  disabled={!isEditMode}
                  required
                />
              </Col>
            </Form.Group>
            
            <Form.Group as={Row} controlId="colors">
              <Form.Label column sm="2" lg="2" md="2" className="mb-4">
                Couleurs (graphiques)
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="text"
                  name="backgroundColor"
                  placeholder="Couleur de fond (ex: rgba(255, 0, 0, 0.2))"
                  value={agenceData ? agenceData.backgroundColor : formData.backgroundColor}
                  onChange={(e) => handleFieldChange(e, "backgroundColor")}
                  disabled={!isEditMode}
                />
              </Col>
              <Col sm="5">
                <Form.Control
                  type="text"
                  name="borderColor"
                  placeholder="Couleur de bordure (ex: rgba(255, 0, 0, 1))"
                  value={agenceData ? agenceData.borderColor : formData.borderColor}
                  onChange={(e) => handleFieldChange(e, "borderColor")}
                  disabled={!isEditMode}
                />
              </Col>
            </Form.Group>
          </Card>
          <Button type="submit" ref={saveButtonRef} style={{ display: "none" }}>
            Enregistrer
          </Button>
        </Form>
        <NotificationManager notificationQueue={notificationQueue} />
        <ConfirmYesNo
          description="Êtes-vous sûr de vouloir supprimer cette agence ?"
          functionYes={handleBtnDelete}
          show={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          loading={deleteMutation.isPending}
        />
      </div>
    </div>
  );
};

export default UpdateAgence;
