import React, { useState, useEffect } from "react";
import { Card, Table, Row, Col, Badge, Accordion, ListGroup } from "react-bootstrap";
import "../../../assets/css/style_management.scss";
import "../../../pages/global/style_global.scss";
import "./managementWidgetStyle.css";
import { Avatar } from "@mui/material";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { formatEdit } from "../../utils/numberFormat";
import { useCustomQuery } from "../../utils/useCustomQuery ";
import Reload from "../../utils/reload";
import { ErrorConnexion } from "../../modal/error500";

const ManagementWidgetFonction = () => {
  const [error, setError] = useState(null);
  const [structure, setStructure] = useState({});

  // Utilisation des bons endpoints pour les données
  const {data : employes, isLoading : isLoadingEmploye} = useCustomQuery(["all_employes"], "all_employes", setError);
  const {data : infoBus, isLoading: isLoadingInfosBus} = useCustomQuery(["info_bus_management"], "info_bus_management", setError);
  const {data : sousAgences, isLoading: isLoadingSousAgences} = useCustomQuery(["all_sous_agences"], "all_sous_agences", setError);
  const {data : agences, isLoading: isLoadingAgences} = useCustomQuery(["all_agences"], "all_agences", setError);

  const StyleCard = {
    background: "#232e45",
  };
  
  const agenceCouleurs = {
    "Agence Pointe-Noire": "#505B9A", 
    "Agence Brazzaville": "#10b981", 
    "Agence Dolisie": "#dc3545", 
  };

  // Fonction pour organiser la structure : agences > sous-agences > employés
  useEffect(() => {
    if (agences && sousAgences && employes) {
      console.log("Données chargées:", { agences, sousAgences, employes });
      
      const structureOrganisee = {};
      
      // Initialiser les agences
      if (Array.isArray(agences)) {
        agences.forEach(agence => {
          structureOrganisee[agence.nom] = {
            id: agence.idAgence,
            nom: agence.nom,
            ville: agence.ville,
            responsable: agence.responsable,
            sousAgences: []
          };
        });
      }
      
      // Ajouter les sous-agences à chaque agence
      if (Array.isArray(sousAgences)) {
        sousAgences.forEach(sousAgence => {
          // Trouver l'agence parente
          const agenceParente = agences.find(a => a.idAgence === parseInt(sousAgence.idAgence));
          
          if (agenceParente && structureOrganisee[agenceParente.nom]) {
            // Trouver le chef (responsable) de cette sous-agence
            const responsable = employes ? employes.find(e => e.idEmploye === parseInt(sousAgence.idEmploye)) : null;
            
            // Trouver l'adjoint
            const adjoint = employes ? employes.find(e => 
              e.agence === agenceParente.nom && 
              e.fonction === "Adjoint chef d'agence"
            ) : null;
            
            // Employés de cette sous-agence (qui ne sont ni chef ni adjoint)
            const employesSousAgence = employes ? employes.filter(e => 
              e.agence === agenceParente.nom && 
              e.fonction !== "Directeur" && 
              e.fonction !== "Chef d'agence" && 
              e.fonction !== "Adjoint chef d'agence"
            ) : [];
            
            structureOrganisee[agenceParente.nom].sousAgences.push({
              id: sousAgence.idSousAgence,
              nom: sousAgence.nom,
              ville: sousAgence.ville,
              adresse: sousAgence.adresse,
              responsable: responsable,
              adjoint: adjoint,
              employes: employesSousAgence
            });
          }
        });
      }
      
      console.log("Structure organisée:", structureOrganisee);
      setStructure(structureOrganisee);
    }
  }, [agences, sousAgences, employes]);

  if (isLoadingEmploye || isLoadingInfosBus || isLoadingSousAgences || isLoadingAgences) return <Reload />;
  if (error) return <ErrorConnexion error={error} />;

  // Débogage supplémentaire pour vérifier les données
  console.log("Rendu avec structure:", structure);
  console.log("Nombre d'agences:", Object.keys(structure).length);

  return (
    <div className="row g-2 mt-4">
      {/* Admin card */}
      <div className="col-lg-12 col-md-12 mb-4">
        <Card className="p-2 shadow-sm" style={StyleCard}>
          <Card.Body>
            <div className="d-flex align-items-center">
              <div className="rounded-circle p-3 me-3" style={{ background: "#038edc" }}>
                <ManageAccountsIcon style={{ fontSize: "2rem", color: "#fff" }} />
              </div>
              <div>
                <Card.Title className="text-white mb-1">Administration</Card.Title>
                <Card.Subtitle className="text-white-50">
                  <span className="fw-bold">Mr Miguel KIDIMBA</span> - Administrateur de l'application
                </Card.Subtitle>
              </div>
              <Badge bg="info" className="ms-auto">Admin</Badge>
            </div>
          </Card.Body>
        </Card>
      </div>
      
      {/* Agences cards */}
      <div className="col-lg-12 col-md-12">
        {Object.keys(structure).length === 0 ? (
          <Card className="p-2 shadow-sm mb-4" style={StyleCard}>
            <Card.Body>
              <p className="text-white text-center mb-0">Aucune agence disponible</p>
            </Card.Body>
          </Card>
        ) : (
          <Accordion defaultActiveKey="0" className="mb-4">
            {Object.entries(structure).map(([nomAgence, agence], agenceIndex) => {
              const agenceCouleur = agenceCouleurs[nomAgence] || "#6c757d";
              
              return (
                <Accordion.Item 
                  key={`agence-${agenceIndex}`} 
                  eventKey={agenceIndex.toString()} 
                  className="border-0 mb-3"
                >
                  <Accordion.Header className="text-white">
                    <div className="d-flex align-items-center w-100">
                      <div className="rounded-circle p-2 me-3" style={{ background: agenceCouleur }}>
                        <AccountBalanceIcon style={{ fontSize: "1.5rem", color: "#fff" }} />
                      </div>
                      <div>
                        <h5 className="mb-0 text-white">{agence.nom} ({agence.ville})</h5>
                        <small className="text-white-50">
                          Responsable: <strong>{agence.responsable || 'Non défini'}</strong>
                        </small>
                      </div>
                      <Badge bg="light" text="dark" className="ms-auto me-3">
                        {agence.sousAgences.length} sous-agences
                      </Badge>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body style={{ background: "#1c2737" }}>
                    {agence.sousAgences.length === 0 ? (
                      <p className="text-white-50 text-center my-3">Aucune sous-agence enregistrée pour cette agence</p>
                    ) : (
                      <Accordion className="custom-inner-accordion mt-2">
                        {agence.sousAgences.map((sousAgence, sousAgenceIndex) => (
                          <Accordion.Item 
                            key={`sous-agence-${sousAgenceIndex}`} 
                            eventKey={`sa-${agenceIndex}-${sousAgenceIndex}`} 
                            className="border-0 mb-2"
                          >
                            <Accordion.Header>
                              <div className="d-flex align-items-center w-100">
                                <div className="rounded-circle p-2 me-3" style={{ background: agenceCouleur, opacity: 0.8 }}>
                                  <BusinessIcon style={{ fontSize: "1.25rem", color: "#fff" }} />
                                </div>
                                <div>
                                  <h6 className="mb-0 text-white">{sousAgence.nom} ({sousAgence.ville})</h6>
                                  <small className="text-white-50">
                                    {sousAgence.responsable ? `Chef: ${sousAgence.responsable.nom} ${sousAgence.responsable.prenom}` : 'Pas de responsable'}
                                  </small>
                                </div>
                                <Badge bg="info" className="ms-auto me-2">
                                  {(sousAgence.employes ? sousAgence.employes.length : 0) + 
                                   (sousAgence.responsable ? 1 : 0) + 
                                   (sousAgence.adjoint ? 1 : 0)} employés
                                </Badge>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <Row>
                                {/* Chef de la sous-agence */}
                                {sousAgence.responsable && (
                                  <Col md={12} className="mb-3">
                                    <Card className="border-0 shadow-sm employee-card">
                                      <Card.Body>
                                        <div className="d-flex align-items-center">
                                          <Avatar 
                                            sx={{ width: 50, height: 50, bgcolor: agenceCouleur }}
                                            alt={sousAgence.responsable.nom}
                                          >
                                            {sousAgence.responsable.nom[0]}
                                          </Avatar>
                                          <div className="ms-3">
                                            <h6 className="mb-0 text-white">{sousAgence.responsable.civilite} {sousAgence.responsable.nom} {sousAgence.responsable.prenom}</h6>
                                            <Badge bg="primary" className="mt-1">Chef de sous-agence</Badge>
                                          </div>
                                          <div className="ms-auto text-end">
                                            <small className="text-white-50 d-block">Contact</small>
                                            <span className="text-white">{sousAgence.responsable.numeroTelephone || 'N/A'}</span>
                                          </div>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                )}
                                
                                {/* Adjoint */}
                                {sousAgence.adjoint && (
                                  <Col md={12} className="mb-3">
                                    <Card className="border-0 employee-card">
                                      <Card.Body>
                                        <div className="d-flex align-items-center">
                                          <Avatar 
                                            sx={{ width: 40, height: 40, bgcolor: "rgba(255,255,255,0.2)" }}
                                            alt={sousAgence.adjoint.nom}
                                          >
                                            {sousAgence.adjoint.nom[0]}
                                          </Avatar>
                                          <div className="ms-3">
                                            <h6 className="mb-0 text-white">{sousAgence.adjoint.civilite} {sousAgence.adjoint.nom} {sousAgence.adjoint.prenom}</h6>
                                            <Badge bg="info" className="mt-1">Adjoint</Badge>
                                          </div>
                                          <div className="ms-auto text-end">
                                            <small className="text-white-50 d-block">Contact</small>
                                            <span className="text-white">{sousAgence.adjoint.numeroTelephone || 'N/A'}</span>
                                          </div>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                )}
                                
                                {/* Autres employés */}
                                {sousAgence.employes && sousAgence.employes.length > 0 && (
                                  <Col md={12}>
                                    <h6 className="text-white mb-3 border-bottom pb-2">
                                      <AssignmentIndIcon className="me-2" /> Personnel ({sousAgence.employes.length})
                                    </h6>
                                    <Row className="g-2">
                                      {sousAgence.employes.map((emp, idx) => (
                                        <Col md={4} key={`emp-${sousAgenceIndex}-${idx}`} className="mb-2">
                                          <ListGroup.Item className="d-flex align-items-center p-2 employee-list-item">
                                            <Avatar 
                                              sx={{ width: 32, height: 32, bgcolor: "rgba(255,255,255,0.1)" }}
                                              alt={emp.nom}
                                              className="me-2"
                                            >
                                              {emp.nom[0]}
                                            </Avatar>
                                            <div>
                                              <small className="text-white d-block">{emp.nom} {emp.prenom}</small>
                                              <Badge bg="secondary" pill style={{ fontSize: "0.65rem" }}>{emp.fonction}</Badge>
                                            </div>
                                          </ListGroup.Item>
                                        </Col>
                                      ))}
                                    </Row>
                                  </Col>
                                )}
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        )}
      </div>
      
      {/* Bus info table */}
      <div className="col-lg-12 col-md-12 mt-3"> 
        <Card className="border-0 shadow-sm" style={StyleCard}>
          <Card.Header className="d-flex align-items-center border-0">
            <h5 className="text-white mb-0">Informations des Bus</h5>
            <Badge bg="info" className="ms-auto">{infoBus.length} bus actifs</Badge>
          </Card.Header>
          <Card.Body className="p-0">
            <Table responsive className="mb-0" variant="dark">
              <thead>
                <tr className="text-center">
                  <th className="border-0">Modèle bus</th>
                  <th className="border-0">Immatriculation</th>
                  <th className="border-0">Nb voyageurs</th>
                  <th className="border-0">Frais carburant</th>
                  <th className="border-0">Ration chauffeur</th>
                  <th className="border-0">Total dépense</th>
                </tr>
              </thead>
              <tbody>
                {infoBus.map((bus, index) => ( 
                  <tr key={bus.immatriculation} className={index % 2 === 0 ? 'bg-opacity-10' : ''}>
                    <td className="text-center">{bus.modele_bus}</td>
                    <td className="text-center">{bus.immatriculation}</td>
                    <td className="text-center">{bus.nombre_voyageurs}</td>
                    <td className="text-end"> 
                      <span>{bus ? formatEdit(bus.frais_carburant) : 0}</span> 
                      <Badge bg="dark" className="ms-2" style={{ fontSize: "0.7rem" }}>FCFA</Badge>
                    </td>
                    <td className="text-end"> 
                      <span>{bus ? formatEdit(bus.ration_chauffeur) : 0}</span> 
                      <Badge bg="dark" className="ms-2" style={{ fontSize: "0.7rem" }}>FCFA</Badge>
                    </td>
                    <td className="text-end"> 
                      <span>{bus ? formatEdit(bus.total_depense) : 0}</span> 
                      <Badge bg="success" className="ms-2" style={{ fontSize: "0.7rem" }}>FCFA</Badge>
                    </td>     
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ManagementWidgetFonction;
