import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import TableAgences from "../../components/widgets/agence/tableAgences";
import TableSousAgences from "../../components/widgets/agence/tableSousAgences";
import Reload from "../../components/utils/reload";
import { ErrorConnexion } from "../../components/modal/error500";
import { useCustomQuery } from "../../components/utils/useCustomQuery ";
import CardWidget from "../../components/customComponents/cardWidget";
import './agencesStyle.css'; // Import du fichier CSS pour les styles personnalisés des onglets

const Agences = () => {
  const [activeTab, setActiveTab] = useState("agences");
  const [error, setError] = useState(null);
  
  // Utilisation des endpoints corrects pour récupérer les données des agences
  const { data: agencesData, isLoading: isLoadingAgences } = useCustomQuery(
    ["agences_list"],
    "all_agences",
    setError
  );
  
  // Utilisation des endpoints corrects pour récupérer les données des sous-agences
  const { data: sousAgencesData, isLoading: isLoadingSousAgences } = useCustomQuery(
    ["sous_agences_list"],
    "all_sous_agences",
    setError
  );

  // Afficher un indicateur de chargement pendant le chargement des données
  if (isLoadingAgences || isLoadingSousAgences) return <Reload />;
  
  // Afficher un message d'erreur en cas d'échec de chargement des données
  if (error) return <ErrorConnexion error={error} />;

  return (
    <div className="m-3 mt-5 pt-3">
      <p className="fw-semibold">
        <span className="text-secondary">Gestion des agences</span>{" "}
        <span className="text-light">&#62;</span>{" "}
        <span className="text-light">Toutes les agences</span>
      </p>
      
      <div className="row">
        <div className="col-12">
          <CardWidget
            titre=""
            fullHeight={false}
            className="pt-1"
            toolbar={
              <Nav
                variant="tabs"
                defaultActiveKey="agences"
                className="custom-tabs"
                onSelect={(selectedKey) => setActiveTab(selectedKey)}
              >
                <Nav.Item className="me-2"
                style = {{background: "#232e45", borderRadius: "10px 10px 0 0"}}
                >
                  <Nav.Link eventKey="agences" className="custom-tab-link">
                    Agences {agencesData ? `(${agencesData.length})` : '(0)'}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                style = {{background: "#232e45", borderRadius: "10px 10px 0 0"}}
                >
                  <Nav.Link eventKey="sous-agences" className="custom-tab-link">
                    Sous-Agences {sousAgencesData  ? `(${sousAgencesData.length})` : '(0)'}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            }
          >
            <div style={{height: '75vh'}}>
              {activeTab === "agences" ? (
                <TableAgences agencesData={agencesData || []} />
              ) : (
                <TableSousAgences sousAgencesData={sousAgencesData || []} />
              )}
            </div>
          </CardWidget>
        </div>
      </div>
    </div>
  );
};

export default Agences;
