import React from "react";
import { Col, Row, Tab, Nav } from "react-bootstrap";
import CardWidget from "../../components/customComponents/cardWidget";
import PerformanceAgenceChart from "../../components/widgets/dashboard/chartsAgence/PerformanceAgenceChart";
import RessourcesHumainesChart from "../../components/widgets/dashboard/chartsAgence/RessourcesHumainesChart";
import ComparaisonSousAgencesChart from "../../components/widgets/dashboard/chartsAgence/ComparaisonSousAgencesChart";
import PerformanceResponsablesChart from "../../components/widgets/dashboard/chartsAgence/PerformanceResponsablesChart";
import './agencesStatistiques.css'; // Nous allons créer ce fichier CSS pour les styles personnalisés

const AgencesStatistiques = () => {
  return (
    <div className="m-3 mt-5 pt-3">
      <p className="fw-semibold">
        <span className="text-secondary">Dashboard</span>{" "}
        <span className="text-light">&#62;</span>{" "}
        <span className="text-light">Statistiques des agences</span>
      </p>
      
      <Tab.Container id="stats-tabs" defaultActiveKey="performances">
        <Row className="mb-4">
          <Col>
            <CardWidget>
              <Nav variant="tabs" className="custom-tabs">
                <Nav.Item>
                  <Nav.Link eventKey="performances" className="custom-tab-link">Performances des agences</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="rh" className="custom-tab-link">Ressources humaines</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="sous-agences" className="custom-tab-link">Comparaison sous-agences</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="responsables" className="custom-tab-link">Suivi des responsables</Nav.Link>
                </Nav.Item>
              </Nav>
              
              <Tab.Content className="mt-4">
                <Tab.Pane eventKey="performances" >
                  <PerformanceAgenceChart />
                </Tab.Pane>
                <Tab.Pane eventKey="rh">
                  <RessourcesHumainesChart />
                </Tab.Pane>
                <Tab.Pane eventKey="sous-agences">
                  <ComparaisonSousAgencesChart />
                </Tab.Pane>
                <Tab.Pane eventKey="responsables">
                  <PerformanceResponsablesChart />
                </Tab.Pane>
              </Tab.Content>
            </CardWidget>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default AgencesStatistiques;
